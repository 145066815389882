/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: string;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: string;
  /**
   * Loose type that allows any value. Be careful when passing in large `Int` or `Float` literals,
   * as they may not be parsed correctly on the server side. Use `String` literals if you are
   * dealing with really large numbers to be on the safe side.
   */
  Mixed: unknown;
};

/** バス・トイレ種別_部屋設備 */
export type BathToiletTypeRoomEquipment = {
  __typename?: 'BathToiletTypeRoomEquipment';
  bath_toilet_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  room_equipment_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** 仲介業者マスタ */
export type Broker = {
  __typename?: 'Broker';
  address: Scalars['String'];
  association_of_fair_trade?: Maybe<Scalars['String']>;
  business_hour: Scalars['String'];
  company_name: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  is_contact_center_assignable: Scalars['Boolean'];
  kenall_address_code?: Maybe<Scalars['String']>;
  license_number: Scalars['String'];
  line_account_url?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone_number: Scalars['String'];
  postal_code: Scalars['String'];
  regular_holiday?: Maybe<Scalars['String']>;
  show_cta_button_online_meeting: Scalars['Boolean'];
  show_cta_button_other_contact: Scalars['Boolean'];
  show_cta_button_visit_store: Scalars['Boolean'];
  store_name: Scalars['String'];
  tracking_phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** 建物 */
export type Building = {
  __typename?: 'Building';
  block_no?: Maybe<Scalars['String']>;
  building_equipment?: Maybe<BuildingEquipment>;
  building_name?: Maybe<Scalars['String']>;
  building_name_furigana?: Maybe<Scalars['String']>;
  construction_date?: Maybe<Scalars['String']>;
  construction_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  house_number?: Maybe<Scalars['String']>;
  house_number_public?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  is_page_published?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  number_of_basements?: Maybe<Scalars['Int']>;
  number_of_ground_floors?: Maybe<Scalars['Int']>;
  postal_code?: Maybe<PostalCode>;
  postal_code_id?: Maybe<Scalars['Int']>;
  property_type_id?: Maybe<Scalars['Int']>;
  room_availability_updated_at?: Maybe<Scalars['DateTime']>;
  rooms: Array<Maybe<Room>>;
  search_building?: Maybe<SearchBuilding>;
  street?: Maybe<Scalars['String']>;
  total_units?: Maybe<Scalars['Int']>;
  town_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/**
 * 建物設備
 * ※ 値変換が必要
 */
export type BuildingEquipment = {
  __typename?: 'BuildingEquipment';
  building?: Maybe<Building>;
  building_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  gabage_storage?: Maybe<Scalars['Int']>;
  gas_type_id?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  is_anytime_garbage_out?: Maybe<Scalars['Boolean']>;
  is_condominium?: Maybe<Scalars['Boolean']>;
  is_designers_mansion?: Maybe<Scalars['Boolean']>;
  is_exist_bicycle_parking?: Maybe<Scalars['Boolean']>;
  is_exist_elevator?: Maybe<Scalars['Boolean']>;
  is_exist_motorcycle_storage?: Maybe<Scalars['Boolean']>;
  is_exist_parcel_box?: Maybe<Scalars['Boolean']>;
  parking_fee?: Maybe<Scalars['Int']>;
  parking_remarks?: Maybe<Scalars['String']>;
  parking_status?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Building items. */
export type BuildingPaginator = {
  __typename?: 'BuildingPaginator';
  /** A list of Building items. */
  data: Array<Building>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/**
 * 手数料
 * ※ 全体的に値変換とデフォルト値設定が必要
 */
export type CompanyContract = {
  __typename?: 'CompanyContract';
  created_at?: Maybe<Scalars['DateTime']>;
  estate_agent_id?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  insurance_company_usage_fee?: Maybe<Scalars['Float']>;
  insurance_company_usage_fee_unit?: Maybe<Scalars['Int']>;
  original_insurance_company?: Maybe<Scalars['String']>;
  original_insurance_company_flg?: Maybe<Scalars['Boolean']>;
  original_surety_company?: Maybe<Scalars['String']>;
  original_surety_company_flg?: Maybe<Scalars['Boolean']>;
  service_fee?: Maybe<Scalars['Float']>;
  service_fee_unit?: Maybe<Scalars['Int']>;
  surety_company_usage_fee?: Maybe<Scalars['Float']>;
  surety_company_usage_fee_unit?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum ConditionColumn {
  AdvertisementRate = 'ADVERTISEMENT_RATE',
  AirConditioningStatus = 'AIR_CONDITIONING_STATUS',
  AppearanceImagePath = 'APPEARANCE_IMAGE_PATH',
  AvailableStartDate = 'AVAILABLE_START_DATE',
  AvailableStartDateText = 'AVAILABLE_START_DATE_TEXT',
  BathToiletTypeName = 'BATH_TOILET_TYPE_NAME',
  BrokerFee = 'BROKER_FEE',
  BuildingAge = 'BUILDING_AGE',
  BuildingCreatedAt = 'BUILDING_CREATED_AT',
  BuildingId = 'BUILDING_ID',
  BuildingName = 'BUILDING_NAME',
  BuildingNameFurigana = 'BUILDING_NAME_FURIGANA',
  BuildingUpdatedAt = 'BUILDING_UPDATED_AT',
  BusRideTime_1 = 'BUS_RIDE_TIME_1',
  BusRideTime_2 = 'BUS_RIDE_TIME_2',
  BusRideTime_3 = 'BUS_RIDE_TIME_3',
  BusRouteName_1 = 'BUS_ROUTE_NAME_1',
  BusRouteName_2 = 'BUS_ROUTE_NAME_2',
  BusRouteName_3 = 'BUS_ROUTE_NAME_3',
  BusStopName_1 = 'BUS_STOP_NAME_1',
  BusStopName_2 = 'BUS_STOP_NAME_2',
  BusStopName_3 = 'BUS_STOP_NAME_3',
  Children = 'CHILDREN',
  CityName = 'CITY_NAME',
  CompanyManagementNumber = 'COMPANY_MANAGEMENT_NUMBER',
  ConstructionDate = 'CONSTRUCTION_DATE',
  ConstructionId = 'CONSTRUCTION_ID',
  ConstructionName = 'CONSTRUCTION_NAME',
  CookingStoveTypeId = 'COOKING_STOVE_TYPE_ID',
  CookingStoveTypeName = 'COOKING_STOVE_TYPE_NAME',
  Corporation = 'CORPORATION',
  CreatedAt = 'CREATED_AT',
  DeleteFlg = 'DELETE_FLG',
  Deposit = 'DEPOSIT',
  DepreciationDeduction = 'DEPRECIATION_DEDUCTION',
  Direction = 'DIRECTION',
  DirectionId = 'DIRECTION_ID',
  EstateAgent = 'ESTATE_AGENT',
  EstateAgentName = 'ESTATE_AGENT_NAME',
  FloorPlanId = 'FLOOR_PLAN_ID',
  FloorPlanImagePath = 'FLOOR_PLAN_IMAGE_PATH',
  FloorPlanName = 'FLOOR_PLAN_NAME',
  Foreigner = 'FOREIGNER',
  FurnitureApplianceStatus = 'FURNITURE_APPLIANCE_STATUS',
  GarbageStorage = 'GARBAGE_STORAGE',
  GasTypeId = 'GAS_TYPE_ID',
  Gender = 'GENDER',
  Id = 'ID',
  InitialCostScore = 'INITIAL_COST_SCORE',
  IntercomSystem = 'INTERCOM_SYSTEM',
  InteriorImagePath = 'INTERIOR_IMAGE_PATH',
  InternetCost = 'INTERNET_COST',
  Is_24HourMonitoring = 'IS_24HOUR_MONITORING',
  IsAnytimeGarbageOut = 'IS_ANYTIME_GARBAGE_OUT',
  IsAutolock = 'IS_AUTOLOCK',
  IsBalcony = 'IS_BALCONY',
  IsBathroomDryer = 'IS_BATHROOM_DRYER',
  IsBathtoiletSeparated = 'IS_BATHTOILET_SEPARATED',
  IsCableTv = 'IS_CABLE_TV',
  IsCaretaker = 'IS_CARETAKER',
  IsCondominium = 'IS_CONDOMINIUM',
  IsCornerRoom = 'IS_CORNER_ROOM',
  IsDesignersMansion = 'IS_DESIGNERS_MANSION',
  IsExistBicycleParking = 'IS_EXIST_BICYCLE_PARKING',
  IsExistElevator = 'IS_EXIST_ELEVATOR',
  IsExistFurnitureAppliance = 'IS_EXIST_FURNITURE_APPLIANCE',
  IsExistMotorcycleParking = 'IS_EXIST_MOTORCYCLE_PARKING',
  IsExistMotorcycleStorage = 'IS_EXIST_MOTORCYCLE_STORAGE',
  IsExistParcelBox = 'IS_EXIST_PARCEL_BOX',
  IsFlooring = 'IS_FLOORING',
  IsFloorHeating = 'IS_FLOOR_HEATING',
  IsFreerent = 'IS_FREERENT',
  IsIndependentWashroom = 'IS_INDEPENDENT_WASHROOM',
  IsInternetAvailable = 'IS_INTERNET_AVAILABLE',
  IsLoft = 'IS_LOFT',
  IsMaisonette = 'IS_MAISONETTE',
  IsOnlyElectricSystems = 'IS_ONLY_ELECTRIC_SYSTEMS',
  IsPrivateYard = 'IS_PRIVATE_YARD',
  IsReadyToPreview = 'IS_READY_TO_PREVIEW',
  IsReheating = 'IS_REHEATING',
  IsRoofBalcony = 'IS_ROOF_BALCONY',
  IsSecurityCamera = 'IS_SECURITY_CAMERA',
  IsSystemKitchen = 'IS_SYSTEM_KITCHEN',
  IsTopFloor = 'IS_TOP_FLOOR',
  IsWalkInCloset = 'IS_WALK_IN_CLOSET',
  IsWashToiletSeat = 'IS_WASH_TOILET_SEAT',
  KeyMoney = 'KEY_MONEY',
  Latitude = 'LATITUDE',
  LocationFloor = 'LOCATION_FLOOR',
  Longitude = 'LONGITUDE',
  Memo = 'MEMO',
  MinProcedureDays = 'MIN_PROCEDURE_DAYS',
  MusicalInstrument = 'MUSICAL_INSTRUMENT',
  NumberOfBasements = 'NUMBER_OF_BASEMENTS',
  NumberOfCookingStove = 'NUMBER_OF_COOKING_STOVE',
  NumberOfGroundFloors = 'NUMBER_OF_GROUND_FLOORS',
  OfficeUse = 'OFFICE_USE',
  Pair = 'PAIR',
  ParkingFee = 'PARKING_FEE',
  ParkingStatus = 'PARKING_STATUS',
  Pets = 'PETS',
  PopularityScore = 'POPULARITY_SCORE',
  PrefectureName = 'PREFECTURE_NAME',
  PropertyTypeId = 'PROPERTY_TYPE_ID',
  PropertyTypeName = 'PROPERTY_TYPE_NAME',
  PublishedFlg = 'PUBLISHED_FLG',
  RailwayLineName = 'RAILWAY_LINE_NAME',
  RailwayLineName_1 = 'RAILWAY_LINE_NAME_1',
  RailwayLineName_2 = 'RAILWAY_LINE_NAME_2',
  RailwayLineName_3 = 'RAILWAY_LINE_NAME_3',
  RenovationFlg = 'RENOVATION_FLG',
  Rent = 'RENT',
  RentAdministrationFee = 'RENT_ADMINISTRATION_FEE',
  ReservationAvailability = 'RESERVATION_AVAILABILITY',
  ReservationInformation = 'RESERVATION_INFORMATION',
  Rooms = 'ROOMS',
  RoomAvailabilityUpdatedAt = 'ROOM_AVAILABILITY_UPDATED_AT',
  RoomCount = 'ROOM_COUNT',
  RoomCreatedAt = 'ROOM_CREATED_AT',
  RoomNumber = 'ROOM_NUMBER',
  RoomShare = 'ROOM_SHARE',
  RoomStatus = 'ROOM_STATUS',
  RoomUpdatedAt = 'ROOM_UPDATED_AT',
  SatelliteBroadcasting = 'SATELLITE_BROADCASTING',
  SecurityDeposit = 'SECURITY_DEPOSIT',
  SecurityTypeName = 'SECURITY_TYPE_NAME',
  SeniorCitizens = 'SENIOR_CITIZENS',
  Single = 'SINGLE',
  SquareMeters = 'SQUARE_METERS',
  StartDate = 'START_DATE',
  StationName = 'STATION_NAME',
  StationName_1 = 'STATION_NAME_1',
  StationName_2 = 'STATION_NAME_2',
  StationName_3 = 'STATION_NAME_3',
  Street = 'STREET',
  Student = 'STUDENT',
  TownName = 'TOWN_NAME',
  TransactionMode = 'TRANSACTION_MODE',
  TypeOfContract = 'TYPE_OF_CONTRACT',
  UpdatedAt = 'UPDATED_AT',
  WalkingTime_1 = 'WALKING_TIME_1',
  WalkingTime_2 = 'WALKING_TIME_2',
  WalkingTime_3 = 'WALKING_TIME_3',
  WashingMachineStorage = 'WASHING_MACHINE_STORAGE'
}

/** 建物構造マスタ */
export type Construction = {
  __typename?: 'Construction';
  construction_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  display_name?: Maybe<Scalars['Int']>;
  homes_search_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_display?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** 不動産会社 */
export type EstateAgent = {
  __typename?: 'EstateAgent';
  account_bank?: Maybe<Scalars['String']>;
  account_bank_number?: Maybe<Scalars['String']>;
  account_branch_office?: Maybe<Scalars['String']>;
  account_branch_office_number?: Maybe<Scalars['String']>;
  account_name?: Maybe<Scalars['String']>;
  /** 値変換が必要 */
  account_number?: Maybe<Scalars['String']>;
  account_type?: Maybe<Scalars['Int']>;
  business_hour?: Maybe<Scalars['String']>;
  business_name?: Maybe<Scalars['String']>;
  company_contract?: Maybe<CompanyContract>;
  company_name?: Maybe<Scalars['String']>;
  converter_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  delete_flg?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  fixed_memo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  initial_cost_name_all?: Maybe<Scalars['String']>;
  license_expiration_date?: Maybe<Scalars['DateTime']>;
  license_number?: Maybe<Scalars['String']>;
  min_procedure_days?: Maybe<Scalars['Int']>;
  monthly_cost_name_all?: Maybe<Scalars['String']>;
  recommendation_comment_public: Scalars['Boolean'];
  regular_holiday?: Maybe<Scalars['String']>;
  rooms?: Maybe<Array<Maybe<Room>>>;
  store_address?: Maybe<Scalars['String']>;
  store_name?: Maybe<Scalars['String']>;
  store_phone_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

/** 不動産会社_保証会社 */
export type EstateAgentSuretyCompany = {
  __typename?: 'EstateAgentSuretyCompany';
  created_at?: Maybe<Scalars['DateTime']>;
  estate_agent_id?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  surety_company_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** 画像 */
export type Image = {
  __typename?: 'Image';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  original_display_order: Scalars['Int'];
  original_name: Scalars['String'];
  room_id: Scalars['Int'];
  type: ImageType;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum ImageType {
  Balcony = 'BALCONY',
  Bathroom = 'BATHROOM',
  Bedroom = 'BEDROOM',
  CookingStove = 'COOKING_STOVE',
  EntranceOfBuilding = 'ENTRANCE_OF_BUILDING',
  EntranceOfRoom = 'ENTRANCE_OF_ROOM',
  EquipmentOfBuilding = 'EQUIPMENT_OF_BUILDING',
  EquipmentOfRoom = 'EQUIPMENT_OF_ROOM',
  FloorPlan = 'FLOOR_PLAN',
  InsideOfRoom = 'INSIDE_OF_ROOM',
  Kitchen = 'KITCHEN',
  Landmark = 'LANDMARK',
  Lavatory = 'LAVATORY',
  Living = 'LIVING',
  OtherOfBuilding = 'OTHER_OF_BUILDING',
  OtherOfRoom = 'OTHER_OF_ROOM',
  OutsideOfBuilding = 'OUTSIDE_OF_BUILDING',
  Parking = 'PARKING',
  Prospect = 'PROSPECT',
  Storage = 'STORAGE',
  Surroundings = 'SURROUNDINGS',
  Unspecified = 'UNSPECIFIED',
  Washbasin = 'WASHBASIN'
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

/** 郵便番号マスタ */
export type PostalCode = {
  __typename?: 'PostalCode';
  city_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  display_order?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  is_display?: Maybe<Scalars['Boolean']>;
  location_code?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  prefecture_name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  town_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type Query = {
  __typename?: 'Query';
  broker?: Maybe<Broker>;
  building?: Maybe<Building>;
  buildings?: Maybe<BuildingPaginator>;
  images: Array<Image>;
  list_page_query?: Maybe<SearchRoomGroupedByBuildingPaginator>;
  list_page_room_query: Array<SearchRoom>;
  room?: Maybe<Room>;
  rooms?: Maybe<RoomPaginator>;
  search_building?: Maybe<SearchBuilding>;
  search_buildings?: Maybe<SearchBuildingPaginator>;
  search_room?: Maybe<SearchRoom>;
  search_rooms?: Maybe<SearchRoomPaginator>;
  search_rooms_table_info?: Maybe<SearchRoomTableInfo>;
};


export type QueryBrokerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBuildingArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBuildingsArgs = {
  first: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Array<QueryBuildingsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  published_flg?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<QueryBuildingsWhereWhereConditions>;
};


export type QueryImagesArgs = {
  room_id?: InputMaybe<Scalars['Int']>;
};


export type QueryList_Page_QueryArgs = {
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<QueryListPageQueryOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QueryListPageQueryWhereWhereConditions>;
};


export type QueryList_Page_Room_QueryArgs = {
  orderBy?: InputMaybe<Array<QueryListPageRoomQueryOrderByOrderByClause>>;
  where?: InputMaybe<QueryListPageRoomQueryWhereWhereConditions>;
};


export type QueryRoomArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRoomsArgs = {
  first: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Array<QueryRoomsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  published_flg?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<QueryRoomsWhereWhereConditions>;
};


export type QuerySearch_BuildingArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySearch_BuildingsArgs = {
  first: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Array<QuerySearchBuildingsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  published_flg?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<QuerySearchBuildingsWhereWhereConditions>;
};


export type QuerySearch_RoomArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySearch_RoomsArgs = {
  first: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Array<QuerySearchRoomsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  published_flg?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<QuerySearchRoomsWhereWhereConditions>;
};

/** Order by clause for the `orderBy` argument on the query `buildings`. */
export type QueryBuildingsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ConditionColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Dynamic WHERE conditions for the `where` argument on the query `buildings`. */
export type QueryBuildingsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryBuildingsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryBuildingsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryBuildingsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<ConditionColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `buildings`. */
export type QueryBuildingsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryBuildingsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Order by clause for the `orderBy` argument on the query `list_page_query`. */
export type QueryListPageQueryOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: SearchRoomGroupedByBuildingColumnEnum;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Dynamic WHERE conditions for the `where` argument on the query `list_page_query`. */
export type QueryListPageQueryWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryListPageQueryWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryListPageQueryWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryListPageQueryWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<ConditionColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `list_page_query`. */
export type QueryListPageQueryWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryListPageQueryWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Order by clause for the `orderBy` argument on the query `list_page_room_query`. */
export type QueryListPageRoomQueryOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ConditionColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Dynamic WHERE conditions for the `where` argument on the query `list_page_room_query`. */
export type QueryListPageRoomQueryWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryListPageRoomQueryWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryListPageRoomQueryWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryListPageRoomQueryWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<ConditionColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `list_page_room_query`. */
export type QueryListPageRoomQueryWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryListPageRoomQueryWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Order by clause for the `orderBy` argument on the query `rooms`. */
export type QueryRoomsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ConditionColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Dynamic WHERE conditions for the `where` argument on the query `rooms`. */
export type QueryRoomsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QueryRoomsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QueryRoomsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QueryRoomsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<ConditionColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `rooms`. */
export type QueryRoomsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QueryRoomsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Order by clause for the `orderBy` argument on the query `search_buildings`. */
export type QuerySearchBuildingsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ConditionColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Dynamic WHERE conditions for the `where` argument on the query `search_buildings`. */
export type QuerySearchBuildingsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QuerySearchBuildingsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QuerySearchBuildingsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QuerySearchBuildingsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<ConditionColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `search_buildings`. */
export type QuerySearchBuildingsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QuerySearchBuildingsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** Order by clause for the `orderBy` argument on the query `search_rooms`. */
export type QuerySearchRoomsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: ConditionColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Dynamic WHERE conditions for the `where` argument on the query `search_rooms`. */
export type QuerySearchRoomsWhereWhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<QuerySearchRoomsWhereWhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<QuerySearchRoomsWhereWhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<QuerySearchRoomsWhereWhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<ConditionColumn>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE conditions for the `where` argument on the query `search_rooms`. */
export type QuerySearchRoomsWhereWhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<QuerySearchRoomsWhereWhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

/** 賃貸借条件 */
export type ReservationInformation = {
  __typename?: 'ReservationInformation';
  advertisement_rate?: Maybe<Scalars['Int']>;
  available_start_date?: Maybe<Scalars['Date']>;
  card_payment_options?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  freerent_available_months?: Maybe<Scalars['Int']>;
  freerent_remarks?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_freerent?: Maybe<Scalars['Boolean']>;
  months_of_contract?: Maybe<Scalars['Int']>;
  /** 値変換が必要 */
  other_cost_1?: Maybe<Scalars['Int']>;
  other_cost_2?: Maybe<Scalars['Int']>;
  other_cost_3?: Maybe<Scalars['Int']>;
  other_cost_4?: Maybe<Scalars['Int']>;
  other_cost_5?: Maybe<Scalars['Int']>;
  other_cost_6?: Maybe<Scalars['Int']>;
  other_cost_7?: Maybe<Scalars['Int']>;
  other_cost_8?: Maybe<Scalars['Int']>;
  other_cost_9?: Maybe<Scalars['Int']>;
  other_cost_10?: Maybe<Scalars['Int']>;
  other_cost_11?: Maybe<Scalars['Int']>;
  other_cost_12?: Maybe<Scalars['Int']>;
  other_cost_13?: Maybe<Scalars['Int']>;
  other_cost_14?: Maybe<Scalars['Int']>;
  other_cost_15?: Maybe<Scalars['Int']>;
  other_cost_16?: Maybe<Scalars['Int']>;
  other_cost_17?: Maybe<Scalars['Int']>;
  other_cost_18?: Maybe<Scalars['Int']>;
  other_cost_19?: Maybe<Scalars['Int']>;
  other_cost_20?: Maybe<Scalars['Int']>;
  other_cost_21?: Maybe<Scalars['Int']>;
  other_cost_22?: Maybe<Scalars['Int']>;
  other_cost_23?: Maybe<Scalars['Int']>;
  other_cost_24?: Maybe<Scalars['Int']>;
  other_cost_25?: Maybe<Scalars['Int']>;
  other_cost_26?: Maybe<Scalars['Int']>;
  other_cost_27?: Maybe<Scalars['Int']>;
  other_cost_28?: Maybe<Scalars['Int']>;
  other_cost_29?: Maybe<Scalars['Int']>;
  other_cost_30?: Maybe<Scalars['Int']>;
  other_cost_class_1?: Maybe<Scalars['Int']>;
  other_cost_class_2?: Maybe<Scalars['Int']>;
  other_cost_class_3?: Maybe<Scalars['Int']>;
  other_cost_class_4?: Maybe<Scalars['Int']>;
  other_cost_class_5?: Maybe<Scalars['Int']>;
  other_cost_class_6?: Maybe<Scalars['Int']>;
  other_cost_class_7?: Maybe<Scalars['Int']>;
  other_cost_class_8?: Maybe<Scalars['Int']>;
  other_cost_class_9?: Maybe<Scalars['Int']>;
  other_cost_class_10?: Maybe<Scalars['Int']>;
  other_cost_class_11?: Maybe<Scalars['Int']>;
  other_cost_class_12?: Maybe<Scalars['Int']>;
  other_cost_class_13?: Maybe<Scalars['Int']>;
  other_cost_class_14?: Maybe<Scalars['Int']>;
  other_cost_class_15?: Maybe<Scalars['Int']>;
  other_cost_class_16?: Maybe<Scalars['Int']>;
  other_cost_class_17?: Maybe<Scalars['Int']>;
  other_cost_class_18?: Maybe<Scalars['Int']>;
  other_cost_class_19?: Maybe<Scalars['Int']>;
  other_cost_class_20?: Maybe<Scalars['Int']>;
  other_cost_class_21?: Maybe<Scalars['Int']>;
  other_cost_class_22?: Maybe<Scalars['Int']>;
  other_cost_class_23?: Maybe<Scalars['Int']>;
  other_cost_class_24?: Maybe<Scalars['Int']>;
  other_cost_class_25?: Maybe<Scalars['Int']>;
  other_cost_class_26?: Maybe<Scalars['Int']>;
  other_cost_class_27?: Maybe<Scalars['Int']>;
  other_cost_class_28?: Maybe<Scalars['Int']>;
  other_cost_class_29?: Maybe<Scalars['Int']>;
  other_cost_class_30?: Maybe<Scalars['Int']>;
  other_cost_name_1?: Maybe<Scalars['String']>;
  other_cost_name_2?: Maybe<Scalars['String']>;
  other_cost_name_3?: Maybe<Scalars['String']>;
  other_cost_name_4?: Maybe<Scalars['String']>;
  other_cost_name_5?: Maybe<Scalars['String']>;
  other_cost_name_6?: Maybe<Scalars['String']>;
  other_cost_name_7?: Maybe<Scalars['String']>;
  other_cost_name_8?: Maybe<Scalars['String']>;
  other_cost_name_9?: Maybe<Scalars['String']>;
  other_cost_name_10?: Maybe<Scalars['String']>;
  other_cost_name_11?: Maybe<Scalars['String']>;
  other_cost_name_12?: Maybe<Scalars['String']>;
  other_cost_name_13?: Maybe<Scalars['String']>;
  other_cost_name_14?: Maybe<Scalars['String']>;
  other_cost_name_15?: Maybe<Scalars['String']>;
  other_cost_name_16?: Maybe<Scalars['String']>;
  other_cost_name_17?: Maybe<Scalars['String']>;
  other_cost_name_18?: Maybe<Scalars['String']>;
  other_cost_name_19?: Maybe<Scalars['String']>;
  other_cost_name_20?: Maybe<Scalars['String']>;
  other_cost_name_21?: Maybe<Scalars['String']>;
  other_cost_name_22?: Maybe<Scalars['String']>;
  other_cost_name_23?: Maybe<Scalars['String']>;
  other_cost_name_24?: Maybe<Scalars['String']>;
  other_cost_name_25?: Maybe<Scalars['String']>;
  other_cost_name_26?: Maybe<Scalars['String']>;
  other_cost_name_27?: Maybe<Scalars['String']>;
  other_cost_name_28?: Maybe<Scalars['String']>;
  other_cost_name_29?: Maybe<Scalars['String']>;
  other_cost_name_30?: Maybe<Scalars['String']>;
  remarks_of_contract?: Maybe<Scalars['String']>;
  room?: Maybe<Room>;
  room_id?: Maybe<Scalars['Int']>;
  room_status?: Maybe<Scalars['Int']>;
  type_of_available_period?: Maybe<Scalars['Int']>;
  type_of_contract?: Maybe<Scalars['Int']>;
  type_of_occupancy?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/**
 * 部屋
 * 何に使うかわからないroom_detail_unit1-10に注意
 */
export type Room = {
  __typename?: 'Room';
  availability_updated_at: Scalars['DateTime'];
  balcony_area?: Maybe<Scalars['Float']>;
  building: Building;
  building_id?: Maybe<Scalars['Int']>;
  company_management_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  direction_id?: Maybe<Scalars['Int']>;
  estate_agent: EstateAgent;
  estate_agent_id?: Maybe<Scalars['Int']>;
  floor_plan_id?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  images: Array<Image>;
  initial_cost_score: Scalars['Float'];
  location_floor?: Maybe<Scalars['String']>;
  property_agent_managed_id: Scalars['String'];
  published_flg?: Maybe<Scalars['Boolean']>;
  recommendation_comment?: Maybe<Scalars['String']>;
  reservation_information?: Maybe<ReservationInformation>;
  room_condition?: Maybe<RoomCondition>;
  room_cost?: Maybe<RoomCost>;
  room_count?: Maybe<Scalars['Int']>;
  room_detail_area_1?: Maybe<Scalars['Float']>;
  room_detail_area_2?: Maybe<Scalars['Float']>;
  room_detail_area_3?: Maybe<Scalars['Float']>;
  room_detail_area_4?: Maybe<Scalars['Float']>;
  room_detail_area_5?: Maybe<Scalars['Float']>;
  room_detail_area_6?: Maybe<Scalars['Float']>;
  room_detail_area_7?: Maybe<Scalars['Float']>;
  room_detail_area_8?: Maybe<Scalars['Float']>;
  room_detail_area_9?: Maybe<Scalars['Float']>;
  room_detail_area_10?: Maybe<Scalars['Float']>;
  room_detail_count_1?: Maybe<Scalars['Int']>;
  room_detail_count_2?: Maybe<Scalars['Int']>;
  room_detail_count_3?: Maybe<Scalars['Int']>;
  room_detail_count_4?: Maybe<Scalars['Int']>;
  room_detail_count_5?: Maybe<Scalars['Int']>;
  room_detail_count_6?: Maybe<Scalars['Int']>;
  room_detail_count_7?: Maybe<Scalars['Int']>;
  room_detail_count_8?: Maybe<Scalars['Int']>;
  room_detail_count_9?: Maybe<Scalars['Int']>;
  room_detail_count_10?: Maybe<Scalars['Int']>;
  room_detail_floor_1?: Maybe<Scalars['Int']>;
  room_detail_floor_2?: Maybe<Scalars['Int']>;
  room_detail_floor_3?: Maybe<Scalars['Int']>;
  room_detail_floor_4?: Maybe<Scalars['Int']>;
  room_detail_floor_5?: Maybe<Scalars['Int']>;
  room_detail_floor_6?: Maybe<Scalars['Int']>;
  room_detail_floor_7?: Maybe<Scalars['Int']>;
  room_detail_floor_8?: Maybe<Scalars['Int']>;
  room_detail_floor_9?: Maybe<Scalars['Int']>;
  room_detail_floor_10?: Maybe<Scalars['Int']>;
  room_detail_id_1?: Maybe<Scalars['Int']>;
  room_detail_id_2?: Maybe<Scalars['Int']>;
  room_detail_id_3?: Maybe<Scalars['Int']>;
  room_detail_id_4?: Maybe<Scalars['Int']>;
  room_detail_id_5?: Maybe<Scalars['Int']>;
  room_detail_id_6?: Maybe<Scalars['Int']>;
  room_detail_id_7?: Maybe<Scalars['Int']>;
  room_detail_id_8?: Maybe<Scalars['Int']>;
  room_detail_id_9?: Maybe<Scalars['Int']>;
  room_detail_id_10?: Maybe<Scalars['Int']>;
  room_equipment?: Maybe<RoomEquipment>;
  room_number?: Maybe<Scalars['String']>;
  room_number_public?: Maybe<Scalars['Boolean']>;
  room_selling_point?: Maybe<RoomSellingPoint>;
  room_surety_company?: Maybe<RoomSuretyCompany>;
  square_meters?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['DateTime']>;
  transaction_mode_id?: Maybe<Scalars['Int']>;
  update_time?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/**
 * 条件
 * ※ 全体的に値変換が必要
 */
export type RoomCondition = {
  __typename?: 'RoomCondition';
  children?: Maybe<Scalars['Int']>;
  corporation?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  foreigner?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  musical_instrument?: Maybe<Scalars['Int']>;
  office_use?: Maybe<Scalars['Int']>;
  pair?: Maybe<Scalars['Int']>;
  pets?: Maybe<Scalars['Int']>;
  room?: Maybe<Room>;
  room_id?: Maybe<Scalars['Int']>;
  room_share?: Maybe<Scalars['Int']>;
  senior_citizens?: Maybe<Scalars['Int']>;
  single?: Maybe<Scalars['Int']>;
  student?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** 費用 */
export type RoomCost = {
  __typename?: 'RoomCost';
  /** 値変換が必要 */
  add_deposit?: Maybe<Scalars['Float']>;
  /** 値変換が必要 */
  add_deposit_reason_id?: Maybe<Scalars['Int']>;
  add_deposit_unit?: Maybe<Scalars['Int']>;
  administration_fee?: Maybe<Scalars['Int']>;
  broker_fee: Scalars['Int'];
  created_at?: Maybe<Scalars['DateTime']>;
  /** 値変換が必要 */
  deposit?: Maybe<Scalars['Float']>;
  deposit_unit?: Maybe<Scalars['Int']>;
  depreciation_deduction?: Maybe<Scalars['Float']>;
  depreciation_deduction_type?: Maybe<Scalars['Int']>;
  depreciation_deduction_unit?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  is_property_insurance_required?: Maybe<Scalars['Int']>;
  key_money?: Maybe<Scalars['Float']>;
  key_money_unit?: Maybe<Scalars['Int']>;
  property_insurance?: Maybe<Scalars['Int']>;
  /** 値変換が必要 */
  renewal_fee?: Maybe<Scalars['Float']>;
  renewal_fee_unit?: Maybe<Scalars['Int']>;
  rent?: Maybe<Scalars['Int']>;
  room?: Maybe<Room>;
  room_id?: Maybe<Scalars['Int']>;
  /** 値変換が必要 */
  security_deposit?: Maybe<Scalars['Float']>;
  security_deposit_unit?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/**
 * 部屋設備
 * ※ 全体的に値変換が必要
 */
export type RoomEquipment = {
  __typename?: 'RoomEquipment';
  air_conditioner_count?: Maybe<Scalars['Int']>;
  air_conditioning_status?: Maybe<Scalars['Int']>;
  bath_status?: Maybe<Scalars['Int']>;
  closet_count?: Maybe<Scalars['Int']>;
  cooking_stove_type_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  furniture_appliance_status?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  intercom_system?: Maybe<Scalars['Int']>;
  internet_cost?: Maybe<Scalars['Int']>;
  is_balcony?: Maybe<Scalars['Boolean']>;
  is_cable_tv?: Maybe<Scalars['Boolean']>;
  is_closet?: Maybe<Scalars['Boolean']>;
  is_corner_room?: Maybe<Scalars['Boolean']>;
  is_counter_kitchen?: Maybe<Scalars['Boolean']>;
  is_disposer?: Maybe<Scalars['Boolean']>;
  is_exist_kitchen?: Maybe<Scalars['Boolean']>;
  is_floor_heating?: Maybe<Scalars['Boolean']>;
  is_flooring?: Maybe<Scalars['Boolean']>;
  is_hot_water_system?: Maybe<Scalars['Boolean']>;
  is_internet_available?: Maybe<Scalars['Boolean']>;
  is_loft?: Maybe<Scalars['Boolean']>;
  is_maisonette?: Maybe<Scalars['Boolean']>;
  is_new_construction?: Maybe<Scalars['Boolean']>;
  is_no_charge_cable_tv?: Maybe<Scalars['Boolean']>;
  is_only_electric_systems?: Maybe<Scalars['Boolean']>;
  is_private_yard?: Maybe<Scalars['Boolean']>;
  is_roof_balcony?: Maybe<Scalars['Boolean']>;
  is_shoes_storage?: Maybe<Scalars['Boolean']>;
  is_subscribed_security_service?: Maybe<Scalars['Boolean']>;
  is_system_kitchen?: Maybe<Scalars['Boolean']>;
  is_walk_in_closet?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  number_of_cooking_stove?: Maybe<Scalars['Int']>;
  number_of_windows?: Maybe<Scalars['Int']>;
  reform_point?: Maybe<Scalars['String']>;
  reform_remarks?: Maybe<Scalars['String']>;
  reform_season?: Maybe<Scalars['String']>;
  renovation_flg?: Maybe<Scalars['Boolean']>;
  room?: Maybe<Room>;
  room_id?: Maybe<Scalars['Int']>;
  satellite_broadcasting?: Maybe<Scalars['Int']>;
  toilet_status?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  washing_machine_storage?: Maybe<Scalars['Int']>;
};

/** A paginated list of Room items. */
export type RoomPaginator = {
  __typename?: 'RoomPaginator';
  /** A list of Room items. */
  data: Array<Room>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** セールスポイント */
export type RoomSellingPoint = {
  __typename?: 'RoomSellingPoint';
  created_at?: Maybe<Scalars['DateTime']>;
  detail_description?: Maybe<Scalars['String']>;
  display_title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  remark?: Maybe<Scalars['String']>;
  room?: Maybe<Room>;
  room_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** 部屋_保証会社 */
export type RoomSuretyCompany = {
  __typename?: 'RoomSuretyCompany';
  created_at?: Maybe<Scalars['DateTime']>;
  custom_surety_company_usage_fee?: Maybe<Scalars['Float']>;
  guarantee_charge?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_necessary_guarantor?: Maybe<Scalars['Boolean']>;
  is_necessary_insurance?: Maybe<Scalars['Boolean']>;
  room?: Maybe<Room>;
  room_id?: Maybe<Scalars['Int']>;
  subscriptiom_form?: Maybe<Scalars['Int']>;
  surety_company?: Maybe<SuretyCompany>;
  /** 値変換が必要 */
  surety_company_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** The available SQL operators that are used to filter query results. */
export enum SqlOperator {
  /** Whether a value is within a range of values (`BETWEEN`) */
  Between = 'BETWEEN',
  /** Equal operator (`=`) */
  Eq = 'EQ',
  /** Greater than operator (`>`) */
  Gt = 'GT',
  /** Greater than or equal operator (`>=`) */
  Gte = 'GTE',
  /** Whether a value is within a set of values (`IN`) */
  In = 'IN',
  /** Whether a value is not null (`IS NOT NULL`) */
  IsNotNull = 'IS_NOT_NULL',
  /** Whether a value is null (`IS NULL`) */
  IsNull = 'IS_NULL',
  /** Simple pattern matching (`LIKE`) */
  Like = 'LIKE',
  /** Less than operator (`<`) */
  Lt = 'LT',
  /** Less than or equal operator (`<=`) */
  Lte = 'LTE',
  /** Not equal operator (`!=`) */
  Neq = 'NEQ',
  /** Whether a value is not within a range of values (`NOT BETWEEN`) */
  NotBetween = 'NOT_BETWEEN',
  /** Whether a value is not within a set of values (`NOT IN`) */
  NotIn = 'NOT_IN',
  /** Negation of simple pattern matching (`NOT LIKE`) */
  NotLike = 'NOT_LIKE'
}

/** 建物（検索用） */
export type SearchBuilding = {
  __typename?: 'SearchBuilding';
  appearance_image_path?: Maybe<Scalars['String']>;
  building: Building;
  building_age?: Maybe<Scalars['Int']>;
  building_created_at?: Maybe<Scalars['DateTime']>;
  building_name?: Maybe<Scalars['String']>;
  building_name_furigana?: Maybe<Scalars['String']>;
  building_updated_at?: Maybe<Scalars['DateTime']>;
  bus_ride_time_1?: Maybe<Scalars['Int']>;
  bus_ride_time_2?: Maybe<Scalars['Int']>;
  bus_ride_time_3?: Maybe<Scalars['Int']>;
  bus_route_name_1?: Maybe<Scalars['String']>;
  bus_route_name_2?: Maybe<Scalars['String']>;
  bus_route_name_3?: Maybe<Scalars['String']>;
  bus_stop_name_1?: Maybe<Scalars['String']>;
  bus_stop_name_2?: Maybe<Scalars['String']>;
  bus_stop_name_3?: Maybe<Scalars['String']>;
  city_name?: Maybe<Scalars['String']>;
  construction_date?: Maybe<Scalars['String']>;
  construction_id?: Maybe<Scalars['Int']>;
  construction_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  garbage_storage?: Maybe<Scalars['Int']>;
  gas_type_id?: Maybe<Scalars['Int']>;
  house_number?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_anytime_garbage_out?: Maybe<Scalars['Boolean']>;
  is_condominium?: Maybe<Scalars['Boolean']>;
  is_designers_mansion?: Maybe<Scalars['Boolean']>;
  is_exist_bicycle_parking?: Maybe<Scalars['Boolean']>;
  is_exist_elevator?: Maybe<Scalars['Boolean']>;
  is_exist_motorcycle_storage?: Maybe<Scalars['Boolean']>;
  is_exist_parcel_box?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  nearest_station_id_1?: Maybe<Scalars['Int']>;
  nearest_station_id_2?: Maybe<Scalars['Int']>;
  nearest_station_id_3?: Maybe<Scalars['Int']>;
  number_of_basements?: Maybe<Scalars['Int']>;
  number_of_ground_floors?: Maybe<Scalars['Int']>;
  parking_fee?: Maybe<Scalars['Int']>;
  parking_status?: Maybe<Scalars['Int']>;
  popularity_score?: Maybe<Scalars['Int']>;
  prefecture_name?: Maybe<Scalars['String']>;
  property_type_id?: Maybe<Scalars['Int']>;
  property_type_name?: Maybe<Scalars['String']>;
  railway_line_name_1?: Maybe<Scalars['String']>;
  railway_line_name_2?: Maybe<Scalars['String']>;
  railway_line_name_3?: Maybe<Scalars['String']>;
  room_availability_updated_at: Scalars['DateTime'];
  rooms: Array<SearchRoom>;
  station_name_1?: Maybe<Scalars['String']>;
  station_name_2?: Maybe<Scalars['String']>;
  station_name_3?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  town_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  walking_time_1?: Maybe<Scalars['Int']>;
  walking_time_2?: Maybe<Scalars['Int']>;
  walking_time_3?: Maybe<Scalars['Int']>;
};

/** A paginated list of SearchBuilding items. */
export type SearchBuildingPaginator = {
  __typename?: 'SearchBuildingPaginator';
  /** A list of SearchBuilding items. */
  data: Array<SearchBuilding>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** 部屋（検索用） */
export type SearchRoom = {
  __typename?: 'SearchRoom';
  administration_fee?: Maybe<Scalars['Int']>;
  advertisement_rate?: Maybe<Scalars['Int']>;
  air_conditioning_status?: Maybe<Scalars['Int']>;
  availability_updated_at: Scalars['DateTime'];
  available_date?: Maybe<Scalars['Date']>;
  available_start_date?: Maybe<Scalars['Date']>;
  available_start_date_text?: Maybe<Scalars['String']>;
  broker: Broker;
  broker_fee: Scalars['Int'];
  building: SearchBuilding;
  building_id?: Maybe<Scalars['Int']>;
  children?: Maybe<Scalars['Int']>;
  company_management_number?: Maybe<Scalars['String']>;
  cooking_stove_type_id?: Maybe<Scalars['Int']>;
  cooking_stove_type_name?: Maybe<Scalars['String']>;
  corporation?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  deposit?: Maybe<Scalars['Float']>;
  deposit_unit?: Maybe<Scalars['Int']>;
  depreciation_deduction?: Maybe<Scalars['Float']>;
  depreciation_deduction_unit?: Maybe<Scalars['Int']>;
  direction?: Maybe<Scalars['String']>;
  direction_id?: Maybe<Scalars['Int']>;
  estate_agent_name?: Maybe<Scalars['String']>;
  floor_plan_image_path?: Maybe<Scalars['String']>;
  floor_plan_name?: Maybe<Scalars['String']>;
  foreigner?: Maybe<Scalars['Int']>;
  freerent_available_months?: Maybe<Scalars['Int']>;
  furniture_appliance_status?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  initial_cost_score: Scalars['Float'];
  intercom_system?: Maybe<Scalars['Int']>;
  interior_image_path?: Maybe<Scalars['String']>;
  internet_cost?: Maybe<Scalars['Int']>;
  is_24hour_monitoring?: Maybe<Scalars['Boolean']>;
  is_autolock?: Maybe<Scalars['Boolean']>;
  is_balcony?: Maybe<Scalars['Boolean']>;
  is_bathroom_dryer?: Maybe<Scalars['Boolean']>;
  is_bathtoilet_separated?: Maybe<Scalars['Boolean']>;
  is_cable_tv?: Maybe<Scalars['Boolean']>;
  is_caretaker?: Maybe<Scalars['Boolean']>;
  is_corner_room?: Maybe<Scalars['Boolean']>;
  is_floor_heating?: Maybe<Scalars['Boolean']>;
  is_flooring?: Maybe<Scalars['Boolean']>;
  is_freerent?: Maybe<Scalars['Boolean']>;
  is_independent_washroom?: Maybe<Scalars['Boolean']>;
  is_internet_available?: Maybe<Scalars['Boolean']>;
  is_loft?: Maybe<Scalars['Boolean']>;
  is_maisonette?: Maybe<Scalars['Boolean']>;
  is_only_electric_systems?: Maybe<Scalars['Boolean']>;
  is_private_yard?: Maybe<Scalars['Boolean']>;
  is_ready_to_preview?: Maybe<Scalars['Boolean']>;
  is_reheating?: Maybe<Scalars['Boolean']>;
  is_roof_balcony?: Maybe<Scalars['Boolean']>;
  is_security_camera?: Maybe<Scalars['Boolean']>;
  is_system_kitchen?: Maybe<Scalars['Boolean']>;
  is_top_floor?: Maybe<Scalars['Boolean']>;
  is_walk_in_closet?: Maybe<Scalars['Boolean']>;
  is_wash_toilet_seat?: Maybe<Scalars['Boolean']>;
  key_money?: Maybe<Scalars['Float']>;
  key_money_unit?: Maybe<Scalars['Int']>;
  location_floor?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  musical_instrument?: Maybe<Scalars['Int']>;
  number_of_cooking_stove?: Maybe<Scalars['Int']>;
  office_use?: Maybe<Scalars['Int']>;
  pair?: Maybe<Scalars['Int']>;
  pets?: Maybe<Scalars['Int']>;
  popularity_score?: Maybe<Scalars['Float']>;
  recommendation_comment?: Maybe<Scalars['String']>;
  renovation_flg?: Maybe<Scalars['Boolean']>;
  rent?: Maybe<Scalars['Int']>;
  rent_administration_fee?: Maybe<Scalars['Int']>;
  reservation_availability?: Maybe<Scalars['Boolean']>;
  room?: Maybe<Room>;
  room_count?: Maybe<Scalars['Int']>;
  room_created_at?: Maybe<Scalars['DateTime']>;
  room_number?: Maybe<Scalars['String']>;
  room_number_public?: Maybe<Scalars['Boolean']>;
  room_share?: Maybe<Scalars['Int']>;
  room_status?: Maybe<Scalars['Int']>;
  room_updated_at?: Maybe<Scalars['DateTime']>;
  satellite_broadcasting?: Maybe<Scalars['Int']>;
  security_deposit?: Maybe<Scalars['Float']>;
  security_deposit_unit?: Maybe<Scalars['Int']>;
  senior_citizens?: Maybe<Scalars['Int']>;
  single?: Maybe<Scalars['Int']>;
  square_meters?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['Date']>;
  student?: Maybe<Scalars['Int']>;
  type_of_available_period?: Maybe<Scalars['Int']>;
  type_of_contract?: Maybe<Scalars['Int']>;
  type_of_occupancy?: Maybe<Scalars['Int']>;
  update_time?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  washing_machine_storage?: Maybe<Scalars['Int']>;
};

export type SearchRoomGroupedByBuilding = {
  __typename?: 'SearchRoomGroupedByBuilding';
  building_id: Scalars['Int'];
  max_popularity_score: Scalars['Float'];
  max_rent_administration_fee: Scalars['Int'];
  max_room_availability_updated_at: Scalars['String'];
  max_square_meters: Scalars['Float'];
  min_rent_administration_fee: Scalars['Int'];
};

export enum SearchRoomGroupedByBuildingColumnEnum {
  BuildingId = 'BUILDING_ID',
  MaxInitialCostScore = 'MAX_INITIAL_COST_SCORE',
  MaxPopularityScore = 'MAX_POPULARITY_SCORE',
  MaxRentAdministrationFee = 'MAX_RENT_ADMINISTRATION_FEE',
  MaxRoomAvailabilityUpdatedAt = 'MAX_ROOM_AVAILABILITY_UPDATED_AT',
  MaxSquareMeters = 'MAX_SQUARE_METERS',
  MinRentAdministrationFee = 'MIN_RENT_ADMINISTRATION_FEE'
}

/** A paginated list of SearchRoomGroupedByBuilding items. */
export type SearchRoomGroupedByBuildingPaginator = {
  __typename?: 'SearchRoomGroupedByBuildingPaginator';
  /** A list of SearchRoomGroupedByBuilding items. */
  data: Array<SearchRoomGroupedByBuilding>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of SearchRoom items. */
export type SearchRoomPaginator = {
  __typename?: 'SearchRoomPaginator';
  /** A list of SearchRoom items. */
  data: Array<SearchRoom>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type SearchRoomTableInfo = {
  __typename?: 'SearchRoomTableInfo';
  latest_updated_at?: Maybe<Scalars['DateTime']>;
  total_count?: Maybe<Scalars['Int']>;
};

/** セキュリティ種別_部屋設備 */
export type SecurityTypeRoomEquipment = {
  __typename?: 'SecurityTypeRoomEquipment';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  room_equipment_id?: Maybe<Scalars['Int']>;
  security_type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** 駅 */
export type Station = {
  __typename?: 'Station';
  created_at?: Maybe<Scalars['DateTime']>;
  display_order?: Maybe<Scalars['Int']>;
  homes_railway_line_code?: Maybe<Scalars['String']>;
  homes_station_code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_display?: Maybe<Scalars['Boolean']>;
  prefecture_code?: Maybe<Scalars['String']>;
  prefecture_name?: Maybe<Scalars['String']>;
  railway_line_name?: Maybe<Scalars['String']>;
  station_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** 保証会社マスタ */
export type SuretyCompany = {
  __typename?: 'SuretyCompany';
  add_deposit_reason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  display_order?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  is_display?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

/** Dynamic WHERE conditions for queries. */
export type WhereConditions = {
  /** A set of conditions that requires all conditions to match. */
  AND?: InputMaybe<Array<WhereConditions>>;
  /** Check whether a relation exists. Extra conditions or a minimum amount can be applied. */
  HAS?: InputMaybe<WhereConditionsRelation>;
  /** A set of conditions that requires at least one condition to match. */
  OR?: InputMaybe<Array<WhereConditions>>;
  /** The column that is used for the condition. */
  column?: InputMaybe<Scalars['String']>;
  /** The operator that is used for the condition. */
  operator?: InputMaybe<SqlOperator>;
  /** The value that is used for the condition. */
  value?: InputMaybe<Scalars['Mixed']>;
};

/** Dynamic HAS conditions for WHERE condition queries. */
export type WhereConditionsRelation = {
  /** The amount to test. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Additional condition logic. */
  condition?: InputMaybe<WhereConditions>;
  /** The comparison operator to test against the amount. */
  operator?: InputMaybe<SqlOperator>;
  /** The relation that is checked. */
  relation: Scalars['String'];
};

export type GetBuildingIdsAndUpdateTimesQueryVariables = Exact<{
  first: Scalars['Int'];
  startEndIds: Scalars['Mixed'];
}>;


export type GetBuildingIdsAndUpdateTimesQuery = { __typename?: 'Query', buildings?: { __typename?: 'BuildingPaginator', data: Array<{ __typename?: 'Building', id: string, search_building?: { __typename?: 'SearchBuilding', room_availability_updated_at: string } | null }> } | null };

export type GetLastBuildingIdQueryVariables = Exact<{
  first?: Scalars['Int'];
}>;


export type GetLastBuildingIdQuery = { __typename?: 'Query', buildings?: { __typename?: 'BuildingPaginator', data: Array<{ __typename?: 'Building', id: string }> } | null };

export type GetLastDetailIdQueryVariables = Exact<{
  first?: Scalars['Int'];
}>;


export type GetLastDetailIdQuery = { __typename?: 'Query', search_rooms?: { __typename?: 'SearchRoomPaginator', data: Array<{ __typename?: 'SearchRoom', id: string }> } | null };

export type GetSearchRoomsByBetweenIdQueryVariables = Exact<{
  first: Scalars['Int'];
  startEndIds: Scalars['Mixed'];
}>;


export type GetSearchRoomsByBetweenIdQuery = { __typename?: 'Query', search_rooms?: { __typename?: 'SearchRoomPaginator', data: Array<{ __typename?: 'SearchRoom', id: string, building_id?: number | null, availability_updated_at: string }> } | null };

export type GetBrokerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBrokerQuery = { __typename?: 'Query', broker?: { __typename?: 'Broker', id: string, company_name: string, store_name: string, email: string, phone_number: string, license_number: string, postal_code: string, address: string, business_hour: string, regular_holiday?: string | null, organization?: string | null, association_of_fair_trade?: string | null } | null };

export type GetRoomsInSameBuildingQueryVariables = Exact<{
  buildingId: Scalars['ID'];
}>;


export type GetRoomsInSameBuildingQuery = { __typename?: 'Query', search_building?: { __typename?: 'SearchBuilding', rooms: Array<{ __typename?: 'SearchRoom', id: string, popularity_score?: number | null }> } | null };


export const GetBuildingIdsAndUpdateTimesDocument = gql`
    query getBuildingIdsAndUpdateTimes($first: Int!, $startEndIds: Mixed!) {
  buildings(
    first: $first
    where: {column: ID, operator: BETWEEN, value: $startEndIds}
  ) {
    data {
      id
      search_building {
        room_availability_updated_at
      }
    }
  }
}
    `;

/**
 * __useGetBuildingIdsAndUpdateTimesQuery__
 *
 * To run a query within a React component, call `useGetBuildingIdsAndUpdateTimesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuildingIdsAndUpdateTimesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuildingIdsAndUpdateTimesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      startEndIds: // value for 'startEndIds'
 *   },
 * });
 */
export function useGetBuildingIdsAndUpdateTimesQuery(baseOptions: Apollo.QueryHookOptions<GetBuildingIdsAndUpdateTimesQuery, GetBuildingIdsAndUpdateTimesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuildingIdsAndUpdateTimesQuery, GetBuildingIdsAndUpdateTimesQueryVariables>(GetBuildingIdsAndUpdateTimesDocument, options);
      }
export function useGetBuildingIdsAndUpdateTimesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuildingIdsAndUpdateTimesQuery, GetBuildingIdsAndUpdateTimesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuildingIdsAndUpdateTimesQuery, GetBuildingIdsAndUpdateTimesQueryVariables>(GetBuildingIdsAndUpdateTimesDocument, options);
        }
export type GetBuildingIdsAndUpdateTimesQueryHookResult = ReturnType<typeof useGetBuildingIdsAndUpdateTimesQuery>;
export type GetBuildingIdsAndUpdateTimesLazyQueryHookResult = ReturnType<typeof useGetBuildingIdsAndUpdateTimesLazyQuery>;
export type GetBuildingIdsAndUpdateTimesQueryResult = Apollo.QueryResult<GetBuildingIdsAndUpdateTimesQuery, GetBuildingIdsAndUpdateTimesQueryVariables>;
export const GetLastBuildingIdDocument = gql`
    query getLastBuildingId($first: Int! = 1) {
  buildings(first: $first, orderBy: {column: ID, order: DESC}) {
    data {
      id
    }
  }
}
    `;

/**
 * __useGetLastBuildingIdQuery__
 *
 * To run a query within a React component, call `useGetLastBuildingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastBuildingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastBuildingIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetLastBuildingIdQuery(baseOptions?: Apollo.QueryHookOptions<GetLastBuildingIdQuery, GetLastBuildingIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastBuildingIdQuery, GetLastBuildingIdQueryVariables>(GetLastBuildingIdDocument, options);
      }
export function useGetLastBuildingIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastBuildingIdQuery, GetLastBuildingIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastBuildingIdQuery, GetLastBuildingIdQueryVariables>(GetLastBuildingIdDocument, options);
        }
export type GetLastBuildingIdQueryHookResult = ReturnType<typeof useGetLastBuildingIdQuery>;
export type GetLastBuildingIdLazyQueryHookResult = ReturnType<typeof useGetLastBuildingIdLazyQuery>;
export type GetLastBuildingIdQueryResult = Apollo.QueryResult<GetLastBuildingIdQuery, GetLastBuildingIdQueryVariables>;
export const GetLastDetailIdDocument = gql`
    query getLastDetailId($first: Int! = 1) {
  search_rooms(first: $first, orderBy: {column: ID, order: DESC}) {
    data {
      id
    }
  }
}
    `;

/**
 * __useGetLastDetailIdQuery__
 *
 * To run a query within a React component, call `useGetLastDetailIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastDetailIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastDetailIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetLastDetailIdQuery(baseOptions?: Apollo.QueryHookOptions<GetLastDetailIdQuery, GetLastDetailIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastDetailIdQuery, GetLastDetailIdQueryVariables>(GetLastDetailIdDocument, options);
      }
export function useGetLastDetailIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastDetailIdQuery, GetLastDetailIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastDetailIdQuery, GetLastDetailIdQueryVariables>(GetLastDetailIdDocument, options);
        }
export type GetLastDetailIdQueryHookResult = ReturnType<typeof useGetLastDetailIdQuery>;
export type GetLastDetailIdLazyQueryHookResult = ReturnType<typeof useGetLastDetailIdLazyQuery>;
export type GetLastDetailIdQueryResult = Apollo.QueryResult<GetLastDetailIdQuery, GetLastDetailIdQueryVariables>;
export const GetSearchRoomsByBetweenIdDocument = gql`
    query getSearchRoomsByBetweenId($first: Int!, $startEndIds: Mixed!) {
  search_rooms(
    first: $first
    where: {column: ID, operator: BETWEEN, value: $startEndIds}
  ) {
    data {
      id
      building_id
      availability_updated_at
    }
  }
}
    `;

/**
 * __useGetSearchRoomsByBetweenIdQuery__
 *
 * To run a query within a React component, call `useGetSearchRoomsByBetweenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchRoomsByBetweenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchRoomsByBetweenIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *      startEndIds: // value for 'startEndIds'
 *   },
 * });
 */
export function useGetSearchRoomsByBetweenIdQuery(baseOptions: Apollo.QueryHookOptions<GetSearchRoomsByBetweenIdQuery, GetSearchRoomsByBetweenIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchRoomsByBetweenIdQuery, GetSearchRoomsByBetweenIdQueryVariables>(GetSearchRoomsByBetweenIdDocument, options);
      }
export function useGetSearchRoomsByBetweenIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchRoomsByBetweenIdQuery, GetSearchRoomsByBetweenIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchRoomsByBetweenIdQuery, GetSearchRoomsByBetweenIdQueryVariables>(GetSearchRoomsByBetweenIdDocument, options);
        }
export type GetSearchRoomsByBetweenIdQueryHookResult = ReturnType<typeof useGetSearchRoomsByBetweenIdQuery>;
export type GetSearchRoomsByBetweenIdLazyQueryHookResult = ReturnType<typeof useGetSearchRoomsByBetweenIdLazyQuery>;
export type GetSearchRoomsByBetweenIdQueryResult = Apollo.QueryResult<GetSearchRoomsByBetweenIdQuery, GetSearchRoomsByBetweenIdQueryVariables>;
export const GetBrokerDocument = gql`
    query getBroker($id: ID!) {
  broker(id: $id) {
    id
    company_name
    store_name
    email
    phone_number
    license_number
    postal_code
    address
    business_hour
    regular_holiday
    organization
    association_of_fair_trade
  }
}
    `;

/**
 * __useGetBrokerQuery__
 *
 * To run a query within a React component, call `useGetBrokerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrokerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrokerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBrokerQuery(baseOptions: Apollo.QueryHookOptions<GetBrokerQuery, GetBrokerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrokerQuery, GetBrokerQueryVariables>(GetBrokerDocument, options);
      }
export function useGetBrokerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrokerQuery, GetBrokerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrokerQuery, GetBrokerQueryVariables>(GetBrokerDocument, options);
        }
export type GetBrokerQueryHookResult = ReturnType<typeof useGetBrokerQuery>;
export type GetBrokerLazyQueryHookResult = ReturnType<typeof useGetBrokerLazyQuery>;
export type GetBrokerQueryResult = Apollo.QueryResult<GetBrokerQuery, GetBrokerQueryVariables>;
export const GetRoomsInSameBuildingDocument = gql`
    query getRoomsInSameBuilding($buildingId: ID!) {
  search_building(id: $buildingId) {
    rooms {
      id
      popularity_score
    }
  }
}
    `;

/**
 * __useGetRoomsInSameBuildingQuery__
 *
 * To run a query within a React component, call `useGetRoomsInSameBuildingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomsInSameBuildingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomsInSameBuildingQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useGetRoomsInSameBuildingQuery(baseOptions: Apollo.QueryHookOptions<GetRoomsInSameBuildingQuery, GetRoomsInSameBuildingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoomsInSameBuildingQuery, GetRoomsInSameBuildingQueryVariables>(GetRoomsInSameBuildingDocument, options);
      }
export function useGetRoomsInSameBuildingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoomsInSameBuildingQuery, GetRoomsInSameBuildingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoomsInSameBuildingQuery, GetRoomsInSameBuildingQueryVariables>(GetRoomsInSameBuildingDocument, options);
        }
export type GetRoomsInSameBuildingQueryHookResult = ReturnType<typeof useGetRoomsInSameBuildingQuery>;
export type GetRoomsInSameBuildingLazyQueryHookResult = ReturnType<typeof useGetRoomsInSameBuildingLazyQuery>;
export type GetRoomsInSameBuildingQueryResult = Apollo.QueryResult<GetRoomsInSameBuildingQuery, GetRoomsInSameBuildingQueryVariables>;