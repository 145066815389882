import { AbstractRouteCondition } from "./Abstract/AbstractRouteCondition";
import { getRailway, getRailwayByLegacyCode, Railway } from "~/types/Models/Railway";
import { getStation, getStationByLegacyCode, Station } from "~/types/Models/Station";
import { nonNullable } from "~/utils/typeUtil";

export class RailwayStationCondition extends AbstractRouteCondition {
  private readonly _railwayIds: number[];
  private readonly _stationIds: number[];

  constructor(_railwayIds: number[], _stationIds: number[]) {
    super();
    this._railwayIds = [...new Set(_railwayIds)];
    this._stationIds = [...new Set(_stationIds)];
  }

  static createByLegacyCode(railwayLegacyCodes: string[], stationLegacyCodes: string[]) {
    const railways = railwayLegacyCodes
      .map((code) => {
        try {
          return getRailwayByLegacyCode(code);
        } catch (e) {
          return undefined;
        }
      })
      .filter((v): v is Railway => !!v);

    const stations = stationLegacyCodes
      .map((code) => {
        try {
          return getStationByLegacyCode(code);
        } catch (e) {
          return undefined;
        }
      })
      .filter((v): v is Station => !!v);

    return new this(
      railways.map((r) => r.id),
      stations.map((s) => s.id),
    );
  }

  get legacy(): boolean {
    return false;
  }

  protected setSourceData(): void {
    const railways = this._railwayIds.map((id) => getRailway(id)).filter(nonNullable);
    const stations = this._stationIds.map((id) => getStation(id)).filter(nonNullable);

    this._sourceData = {
      railways,
      stations,
    };
  }
}
