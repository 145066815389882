import { ParsedUrlQuery } from "querystring";
import Conditions from "./Conditions";
import QueryBuilder from "./QueryBuilder";
import { QUERY_KEY } from "./QueryKey";
import { StringQuery, DictionaryQuery } from "./QueryType";
import { BOOLEAN_TRUE } from "./QueryValue";
import { valueOf } from "~/utils/typeUtil";

/**
 * 検索条件の変更を管理するクラス
 */
export default class ConditionReplacer {
  conditions: Conditions;
  query: ParsedUrlQuery;
  queryBuilder: QueryBuilder;

  /** キーワード条件を更新して返す */
  static replaceKeyword = (
    query: ParsedUrlQuery,
    newValue: valueOf<StringQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.KEYWORD, newValue);
    return queryBuilder.build();
  };

  /** 最低初期トク条件を更新して返す */
  static replaceLowerInitialCostScore = (
    query: ParsedUrlQuery,
    newValue: valueOf<StringQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.INITIAL_COST_SCORE, newValue);
    return queryBuilder.build();
  };

  /** 最低家賃条件を更新して返す */
  static replaceLowerRent = (
    query: ParsedUrlQuery,
    newValue: valueOf<StringQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.LOWER_RENT, newValue);
    return queryBuilder.build();
  };

  /** 最高家賃条件を更新して返す */
  static replaceUpperRent = (
    query: ParsedUrlQuery,
    newValue: valueOf<StringQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.UPPER_RENT, newValue);
    return queryBuilder.build();
  };

  /** 家賃の管理費込み条件を更新して返す */
  static replaceIncludeAdminFree = (
    query: ParsedUrlQuery,
    includeAdminFee: boolean,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);

    queryBuilder.transformQuery(QUERY_KEY.INCLUDE_ADMIN_FREE, includeAdminFee ? BOOLEAN_TRUE : "");
    return queryBuilder.build();
  };

  /** 徒歩分条件を更新して返す */
  static replaceUpperFoot = (
    query: ParsedUrlQuery,
    newValue: valueOf<StringQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.UPPER_FOOT, newValue);
    return queryBuilder.build();
  };

  /** 最低面積条件を更新して返す */
  static replaceLowerArea = (
    query: ParsedUrlQuery,
    newValue: valueOf<StringQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.LOWER_AREA, newValue);
    return queryBuilder.build();
  };

  /** 最高面積条件を更新して返す */
  static replaceUpperArea = (
    query: ParsedUrlQuery,
    newValue: valueOf<StringQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.UPPER_AREA, newValue);
    return queryBuilder.build();
  };

  /** 最高築年条件を更新して返す */
  static replaceUpperBuildingAge = (
    query: ParsedUrlQuery,
    newValue: valueOf<StringQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.UPPER_BUILDING_AGE, newValue);
    return queryBuilder.build();
  };

  /** 間取り条件を更新して返す */
  static replaceFloorPlan = (
    query: ParsedUrlQuery,
    newValue: valueOf<DictionaryQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.FLOOR_PLAN, newValue);
    return queryBuilder.build();
  };

  /** 構造条件を更新して返す */
  static replaceConstruction = (
    query: ParsedUrlQuery,
    newValue: valueOf<DictionaryQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.CONSTRUCTION, newValue);
    return queryBuilder.build();
  };

  /** こだわり条件を更新して返す */
  static replaceCategory = (
    query: ParsedUrlQuery,
    newValue: valueOf<DictionaryQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.CATEGORY, newValue);
    return queryBuilder.build();
  };

  /** こだわり条件を更新して返す */
  static replaceCategoryQuery = (
    query: ParsedUrlQuery,
    newValue: valueOf<DictionaryQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.CATEGORY, newValue);
    return queryBuilder.build();
  };

  /** 並び替え条件を更新して返す */
  static replaceOrderQuery = (
    query: ParsedUrlQuery,
    newValue: valueOf<StringQuery>,
  ): ParsedUrlQuery => {
    const queryBuilder = this.makeBuilder(query);
    queryBuilder.transformQuery(QUERY_KEY.ORDER, newValue);
    return queryBuilder.build();
  };

  private static makeBuilder(query: ParsedUrlQuery): QueryBuilder {
    return new QueryBuilder(query);
  }
}
