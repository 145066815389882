import { AbstractRegionCondition } from "./Abstract/AbstractRegionCondition";
import { getMunicipality, Municipality } from "~/types/Models/Municipality";
import { getMunicipalityGroup, QueryableMunicipalityGroup } from "~/types/Models/MunicipalityGroup";
import { getPrefecture, Prefecture } from "~/types/Models/Prefecture";
import { nonNullable } from "~/utils/typeUtil";

const shikugunGroupIdMap: { [code: string]: number } = {
  "131000": 1,
  "141003": 2,
  "141305": 3,
  "141500": 4,
};

export class MunicipalityCondition extends AbstractRegionCondition {
  private readonly _prefectureSlugs: string[];
  private readonly _municipalityGroupIds: number[];
  private readonly _jisX_0401_0402Codes: string[];

  constructor(
    _prefectureSlugs: string[],
    _municipalityGroupIds: number[],
    _jisX_0401_0402Codes: string[],
  ) {
    super();
    this._prefectureSlugs = [...new Set(_prefectureSlugs)];
    this._municipalityGroupIds = [...new Set(_municipalityGroupIds)];
    this._jisX_0401_0402Codes = [...new Set(_jisX_0401_0402Codes)];
  }

  static createByLegacyCode(
    prefLegacyCodes: string[],
    mGroupLegacyCodes: string[],
    legacyJisCodes: string[],
  ) {
    const prefectures = prefLegacyCodes
      .map((code) => {
        try {
          return getPrefecture({ jisX_0401: code });
        } catch (e) {
          return undefined;
        }
      })
      .filter((v): v is Prefecture => !!v);

    const groups = mGroupLegacyCodes
      .map((code) => {
        try {
          return getMunicipalityGroup({ id: shikugunGroupIdMap[code] });
        } catch (e) {
          return undefined;
        }
      })
      .filter((v): v is QueryableMunicipalityGroup => !!v);

    const municipalities = legacyJisCodes
      .map((code) => {
        try {
          return getMunicipality(code.slice(0, 5));
        } catch (e) {
          return undefined;
        }
      })
      .filter((v): v is Municipality => !!v);

    return new this(
      prefectures.map((p) => p.slug),
      groups.map((g) => g.id),
      municipalities.map((m) => m.jisX_0401_0402),
    );
  }

  get legacy(): boolean {
    return false;
  }

  protected setSourceData(): void {
    const prefectures = this._prefectureSlugs
      .map((slug) => {
        try {
          return getPrefecture({ slug });
        } catch (e) {
          if (e instanceof ReferenceError) return undefined;
          throw e;
        }
      })
      .filter(nonNullable);
    const municipalityGroups = this._municipalityGroupIds
      .map((id) => {
        try {
          return getMunicipalityGroup({ id });
        } catch (e) {
          if (e instanceof ReferenceError) return undefined;
          throw e;
        }
      })
      .filter(nonNullable);
    const municipalities = this._jisX_0401_0402Codes
      .map((code) => {
        try {
          return getMunicipality(code);
        } catch (e) {
          if (e instanceof ReferenceError) return undefined;
          throw e;
        }
      })
      .filter(nonNullable);

    this._sourceData = {
      prefectures,
      municipalityGroups,
      municipalities,
    };
  }
}
