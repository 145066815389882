import { FloorPlanConditionHelper } from "../../Condition/FloorPlanCondition";
import Conditions from "./Conditions";
import { BREAD_CRUMB_TOP_TEXT } from "~/const/seo";
import { getMunicipalityGroup } from "~/types/Models/MunicipalityGroup";
import { getPrefecture } from "~/types/Models/Prefecture";
import { removeParameters } from "~/utils/urlUtil";

type Breadcrumb = { page: string; url?: string };

export class ConditionBreadcrumb {
  constructor(private readonly _conditions: Conditions) {}

  crumbs(pathName: string): Breadcrumb[] {
    const breadList: { page: string; url?: string }[] = [{ page: BREAD_CRUMB_TOP_TEXT, url: "/" }];
    if (!this.isStructural) {
      breadList.push({ page: this._conditions.labels.tintaiPrefix });
      return breadList;
    }

    const crumbData = this.crumbData(pathName);
    if (crumbData.prefecture) {
      breadList.push(crumbData.prefecture);
    } else if (crumbData.mGroup) {
      breadList.push(...crumbData.mGroup);
    } else if (crumbData.municipality) {
      breadList.push(...crumbData.municipality);
    }

    if (crumbData.railway) {
      breadList.push(crumbData.railway);
    } else if (crumbData.station) {
      breadList.push(...crumbData.station);
    }

    if (crumbData.other) {
      breadList.push(crumbData.other);
    }

    // すべての物件
    if (breadList.length === 1) {
      breadList.push({ page: "すべて", url: "/list" });
    }

    // パンくずの最後の要素について、語尾に「の賃貸情報」をつける
    const lastBread = breadList.slice(-1)[0];
    lastBread.page = lastBread.page + "の賃貸物件";
    breadList.splice(-1, 1, lastBread);

    const page = Number(this._conditions.pageCondition);
    if (page > 1) {
      breadList.push({ page: `${page}ページ目` });
    }

    return breadList;
  }

  private crumbData(pathName: string) {
    return {
      prefecture: this.prefCrumb,
      mGroup: this.mGroupCrumb,
      municipality: this.municipalityCrumb,
      railway: this.railwayCrumb,
      station: this.stationCrumb,
      other: this.otherCrumb(pathName),
    };
  }

  private get prefCrumb(): Breadcrumb | undefined {
    const prefecture = this._conditions.regionCondition.optimizedData.prefectures[0];
    if (!prefecture) return;

    return {
      page: prefecture.displayName,
      url: prefecture.url,
    };
  }

  private get mGroupCrumb(): Breadcrumb[] | undefined {
    const mGroup = this._conditions.regionCondition.optimizedData.queryableGroups[0];
    if (!mGroup) return;

    const pref = getPrefecture({ slug: mGroup.prefectureSlug });
    return [
      {
        page: pref.displayName,
        url: pref.url,
      },
      {
        page: mGroup.crumbName,
        url: mGroup.url,
      },
    ];
  }

  private get municipalityCrumb(): Breadcrumb[] | undefined {
    const municipality = this._conditions.regionCondition.optimizedData.municipalities[0];
    if (!municipality) return;

    const group = getMunicipalityGroup({ slug: municipality.municipalityGroupSlug });
    const pref = getPrefecture({ slug: group.prefectureSlug });
    return [
      {
        page: pref.displayName,
        url: pref.url,
      },
      ...(group?.id
        ? [
            {
              page: group.crumbName,
              url: group.url,
            },
          ]
        : []),
      {
        page: municipality.displayName,
        url: municipality.url,
      },
    ];
  }

  private get railwayCrumb(): Breadcrumb | undefined {
    const railway = this._conditions.routeCondition.optimizedData.railways[0];
    if (!railway) return;

    return {
      page: railway.displayName,
      url: railway.url,
    };
  }

  private get stationCrumb(): Breadcrumb[] | undefined {
    const station = this._conditions.routeCondition.optimizedData.stations[0];
    if (!station) return;
    if (!station.municipality) {
      // 市区町村を取得できない場合はパンクズに表示しない
      return [
        {
          page: station.displayName,
          url: station.url,
        },
      ];
    }

    return [
      {
        page: station.municipality.prefecture.displayName,
        url: station.municipality.prefecture.url,
      },
      ...(station.municipality.municipalityGroup?.url
        ? [
            {
              page: station.municipality.municipalityGroup.displayName,
              url: station.municipality.municipalityGroup.url,
            },
          ]
        : []),
      {
        page: station.municipality.displayName,
        url: station.municipality.url,
      },
      {
        page: station.displayName,
        url: station.url,
      },
    ];
  }

  private otherCrumb(pathName: string): Breadcrumb | undefined {
    // その他検索条件どれでも
    // ※ 1個以下であることは保証されている
    // ※ 必ず現在のURLであることは保証されている
    const other = this._conditions.labels.otherCrumbText;
    if (!other) return;

    return {
      page: other,
      url: removeParameters(pathName, ["p", "o"]),
    };
  }

  /**
   * パンくずリストへの詳細項目追加フラグ
   */
  get isStructural(): boolean {
    if (
      this._conditions.selectedAreaConditionLength === 0 &&
      this._conditions.selectedOtherConditionLength === 0
    ) {
      /** /list？p=3など何も条件がないケースは含めない **/
      return false;
    }

    return Boolean(
      (this._conditions.queryAreaCount === this._conditions.selectedAreaConditionLength &&
        this._conditions.selectedAreaConditionLength <= 1 &&
        (this._conditions.selectedOtherConditionLength === 0 ||
          (this._conditions.selectedOtherConditionLength === 1 &&
            (FloorPlanConditionHelper.getSelectedFloorPlans(this._conditions.floorPlanCondition)
              .length > 0 ||
              this._conditions.selectedArticle)))) ||
        (this._conditions.selectedAreaConditionLength === 0 &&
          this._conditions.keywordCondition &&
          this._conditions.selectedOtherConditionLength === 1),
    );
  }
}
