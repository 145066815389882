import { ParsedUrlQuery } from "querystring";
import { RegionConditionInterface } from "./Abstract/AbstractRegionCondition";
import { RouteConditionInterface } from "./Abstract/AbstractRouteCondition";
import { ConditionBreadcrumb } from "./ConditionBreadcrumb";
import { ConditionLabel } from "./ConditionLabel";
import ConditionReplacer from "./ConditionReplacer";
import { IncompatibleError } from "./Error/IncompatibleError";
import { MunicipalityCondition } from "./MunicipalityCodition";
import { QUERY_KEY } from "./QueryKey";
import { StringQuery, DictionaryQuery } from "./QueryType";
import { BOOLEAN_TRUE } from "./QueryValue";
import { RailwayStationCondition } from "./RailwayStationCondition";
import { CategoryConditionHelper } from "@/pages/list/Condition/CategoryCondition";
import { ConstructionConditionHelper } from "@/pages/list/Condition/ConstructionCondition";
import { FloorPlanConditionHelper } from "@/pages/list/Condition/FloorPlanCondition";
import { Article } from "~/types/ArticleType";

import { Prefecture } from "~/types/Models/Prefecture";

import { valueOf } from "~/utils/typeUtil";
const parseArrayQuery = (text: string | null): string[] => {
  if (!text) return [];

  return text.split(",").filter(Boolean);
};

/**
 * リストページの検索条件をまとめたクラス
 */
export default class Conditions {
  /** 沿線・駅条件 */
  private _railwayStationCondition: RouteConditionInterface;
  /** 都道府県・市区郡条件 */
  private _municipalityCondition: RegionConditionInterface;
  /** キーワード条件 */
  keywordCondition: valueOf<StringQuery>;
  /** 最低初期トク条件 */
  lowerInitialCostScoreCondition: valueOf<StringQuery>;
  /** 最低家賃条件 */
  lowerRentCondition: valueOf<StringQuery>;
  /** 最高家賃条件 */
  upperRentCondition: valueOf<StringQuery>;
  /** 家賃の管理費込み条件 */
  includeAdministrationFeeCondition: boolean;
  /** 徒歩分条件 */
  upperFootCondition: valueOf<StringQuery>;
  /** 最低面積条件 */
  lowerAreaCondition: valueOf<StringQuery>;
  /** 最高面積条件 */
  upperAreaCondition: valueOf<StringQuery>;
  /** 最低築年条件 */
  lowerBuildingAgeCondition: valueOf<StringQuery>;
  /** 最高築年条件 */
  upperBuildingAgeCondition: valueOf<StringQuery>;
  /** 間取り条件 */
  floorPlanCondition: valueOf<DictionaryQuery>;
  /** 構造条件 */
  constructionCondition: valueOf<DictionaryQuery>;
  /** こだわり条件 */
  categoriesCondition: valueOf<DictionaryQuery>;
  /** 並び替え条件 */
  orderCondition: valueOf<StringQuery>;
  private readonly _hasOrderConditionQuery: boolean;
  /** ページ条件 */
  pageCondition: valueOf<StringQuery>;
  /** 管理会社名の検索条件 */
  propertyAgentNameCondition: valueOf<StringQuery>;

  /** クエリで指定されているarticle */
  selectedArticle: Article | undefined;

  /** string型のURLクエリ */
  private stringQuery: StringQuery;
  /** 辞書型のURLクエリ */
  private dictionaryQuery: DictionaryQuery;

  constructor(
    conditions: {
      keywordCondition: valueOf<StringQuery>;
      lowerInitialCostScoreCondition: valueOf<StringQuery>;
      lowerRentCondition: valueOf<StringQuery>;
      upperRentCondition: valueOf<StringQuery>;
      includeAdministrationFeeCondition: boolean;
      upperFootCondition: valueOf<StringQuery>;
      lowerAreaCondition: valueOf<StringQuery>;
      upperAreaCondition: valueOf<StringQuery>;
      floorPlanCondition: valueOf<DictionaryQuery>;
      lowerBuildingAgeCondition: valueOf<StringQuery>;
      upperBuildingAgeCondition: valueOf<StringQuery>;
      constructionCondition: valueOf<DictionaryQuery>;
      categoriesCondition: valueOf<DictionaryQuery>;
      orderCondition: valueOf<StringQuery>;
      hasOrderConditionQuery: boolean;
      pageCondition: valueOf<StringQuery>;
      propertyAgentNameCondition: valueOf<StringQuery>;
      railwayLegacyCodes: string[];
      stationLegacyCodes: string[];
      railwayIdsQueryText: valueOf<StringQuery>;
      stationIdsQueryText: valueOf<StringQuery>;
      prefectureLegacyCodes: string[];
      municipalityGroupLegacyCodes: string[];
      municipalityLegacyCodes: string[];
      prefectureSlugsQueryText: valueOf<StringQuery>;
      municipalityGroupIdsQueryText: valueOf<StringQuery>;
      jisCodesQueryText: valueOf<StringQuery>;
    },
    parsedQueries: {
      stringQuery: StringQuery;
      dictionaryQuery: DictionaryQuery;
      articleStringQuery: StringQuery;
      articleDictionaryQuery: DictionaryQuery;
    },
    private readonly _origin: ParsedUrlQuery,
    private readonly _article: Article | undefined,
  ) {
    this.keywordCondition = conditions.keywordCondition;
    this.lowerInitialCostScoreCondition = conditions.lowerInitialCostScoreCondition;
    this.lowerRentCondition = conditions.lowerRentCondition;
    this.upperRentCondition = conditions.upperRentCondition;
    this.upperFootCondition = conditions.upperFootCondition;
    this.includeAdministrationFeeCondition = conditions.includeAdministrationFeeCondition;
    this.lowerAreaCondition = conditions.lowerAreaCondition;
    this.upperAreaCondition = conditions.upperAreaCondition;
    this.floorPlanCondition = conditions.floorPlanCondition;
    this.lowerBuildingAgeCondition = conditions.lowerBuildingAgeCondition;
    this.upperBuildingAgeCondition = conditions.upperBuildingAgeCondition;
    this.constructionCondition = conditions.constructionCondition;
    this.categoriesCondition = conditions.categoriesCondition;
    this.orderCondition = conditions.orderCondition;
    this._hasOrderConditionQuery = conditions.hasOrderConditionQuery;
    this.pageCondition = conditions.pageCondition;
    this.propertyAgentNameCondition = conditions.propertyAgentNameCondition;

    if (conditions.railwayLegacyCodes.length > 0 || conditions.stationLegacyCodes.length > 0) {
      this._railwayStationCondition = RailwayStationCondition.createByLegacyCode(
        conditions.railwayLegacyCodes,
        conditions.stationLegacyCodes,
      );
    } else {
      const railwayIds = parseArrayQuery(conditions.railwayIdsQueryText).map((id) => Number(id));
      const stationIds = parseArrayQuery(conditions.stationIdsQueryText).map((id) => Number(id));

      this._railwayStationCondition = new RailwayStationCondition(railwayIds, stationIds);
    }

    if (
      conditions.prefectureLegacyCodes.length > 0 ||
      conditions.municipalityGroupLegacyCodes.length > 0 ||
      conditions.municipalityLegacyCodes.length > 0
    ) {
      this._municipalityCondition = MunicipalityCondition.createByLegacyCode(
        conditions.prefectureLegacyCodes,
        conditions.municipalityGroupLegacyCodes,
        conditions.municipalityLegacyCodes,
      );
    } else {
      this._municipalityCondition = new MunicipalityCondition(
        parseArrayQuery(conditions.prefectureSlugsQueryText),
        parseArrayQuery(conditions.municipalityGroupIdsQueryText).map((id) => Number(id)),
        parseArrayQuery(conditions.jisCodesQueryText),
      );
    }

    this.selectedArticle = Article.find(parsedQueries.stringQuery[QUERY_KEY.ARTICLE]);

    this.stringQuery = parsedQueries.stringQuery;
    this.dictionaryQuery = parsedQueries.dictionaryQuery;
  }

  /** エリア条件の設定数 */
  get selectedAreaConditionLength(): number {
    return this.routeCondition.conditionCount + this.regionCondition.conditionCount;
  }

  /** エリア系クエリの数 */
  get queryAreaCount(): number {
    return this.routeCondition.variantCount + this.regionCondition.variantCount;
  }

  /** エリア以外の条件設定数（articleの条件は1つと数える） */
  get selectedOtherConditionLength(): number {
    return (
      CategoryConditionHelper.getSelectedCategoryConditions(
        this.dictionaryQuery[QUERY_KEY.CATEGORY] || {},
      )?.length +
      FloorPlanConditionHelper.getSelectedFloorPlans(
        this.dictionaryQuery[QUERY_KEY.FLOOR_PLAN] || {},
      )?.length +
      (this.stringQuery[QUERY_KEY.INITIAL_COST_SCORE] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.LOWER_RENT] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.UPPER_RENT] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.UPPER_FOOT] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.LOWER_AREA] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.UPPER_AREA] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.LOWER_BUILDING_AGE] ? 1 : 0) +
      (this.stringQuery[QUERY_KEY.UPPER_BUILDING_AGE] ? 1 : 0) +
      (ConstructionConditionHelper.getText(this.dictionaryQuery[QUERY_KEY.CONSTRUCTION] || {})
        ? 1
        : 0) +
      (this.stringQuery[QUERY_KEY.KEYWORD] ? 1 : 0) +
      (this.selectedArticle ? 1 : 0)
    );
  }

  /** articleもしくはFloorPlanの条件設定数（index用） */
  get selectedArticleOrFloorPlanConditionLength(): number {
    return (
      FloorPlanConditionHelper.getSelectedFloorPlans(
        this.dictionaryQuery[QUERY_KEY.FLOOR_PLAN] || {},
      )?.length + (this.selectedArticle ? 1 : 0)
    );
  }

  get routeCondition(): RouteConditionInterface {
    return this._railwayStationCondition;
  }

  get regionCondition(): RegionConditionInterface {
    return this._municipalityCondition;
  }

  get isIndexable(): boolean {
    return (
      !this._hasOrderConditionQuery &&
      !(Number(this.selectedArticle?.id) >= 100) &&
      ![1, 5, 21].includes(Number(this.selectedArticle?.id)) &&
      this.routeCondition.isCanonical &&
      this.isValidOriginQuery &&
      this.breadcrumb.isStructural
    );
  }

  private get isValidOriginQuery(): boolean {
    // a_id関連のクエリは変換した上で比較する
    // pクエリは正規化の判定には含めない
    const normalizedOrigin = Object.entries(this._origin)
      .map(([key, value]) => {
        if (key === QUERY_KEY.ARTICLE && Article.find(String(value))) {
          const article = Article.find(String(value));
          if (!article) return;

          return Object.entries(article.search_query).filter(([, value]) => value !== "");
        }
        if (key === QUERY_KEY.PAGE) return [];
        return [[key, value]];
      })
      .flat() as string[][];

    return JSON.stringify(Object.fromEntries(normalizedOrigin)) === JSON.stringify(this.query);
  }

  get labels(): ConditionLabel {
    return new ConditionLabel(this);
  }

  get breadcrumb(): ConditionBreadcrumb {
    return new ConditionBreadcrumb(this);
  }

  setRouteCondition(newOne: RouteConditionInterface): void {
    this._railwayStationCondition = newOne;
  }

  /**
   * deprecated
   */
  forceSetRouteCondition(newOne: RouteConditionInterface): void {
    this.setRouteCondition(newOne);
  }

  setRegionCondition(newOne: RegionConditionInterface): void {
    this._municipalityCondition = newOne;
  }

  /**
   * deprecated
   */
  forceSetRegionCondition(newOne: RegionConditionInterface): void {
    this.setRegionCondition(newOne);
  }

  setLowerInitialCostScoreCondition(lowerInitialCostScoreCondition: valueOf<StringQuery>): void {
    this.lowerInitialCostScoreCondition = lowerInitialCostScoreCondition;
  }

  setUpperRentCondition(upperRentCondition: valueOf<StringQuery>): void {
    this.upperRentCondition = upperRentCondition;
  }

  setUpperFootCondition(upperFootCondition: valueOf<StringQuery>): void {
    this.upperFootCondition = upperFootCondition;
  }

  setLowerBuildingAgeCondition(lowerBuildingAgeCondition: valueOf<StringQuery>): void {
    this.lowerBuildingAgeCondition = lowerBuildingAgeCondition;
  }

  setUpperBuildingAgeCondition(upperBuildingAgeCondition: valueOf<StringQuery>): void {
    this.upperBuildingAgeCondition = upperBuildingAgeCondition;
  }

  setCategoriesCondition(categoriesCondition: valueOf<DictionaryQuery>): void {
    this.categoriesCondition = categoriesCondition;
  }

  removeFootCondition(): void {
    this.setUpperFootCondition("");
  }

  removeBuildingAgeConditions(): void {
    this.setLowerBuildingAgeCondition("");
    this.setUpperBuildingAgeCondition("");
  }

  removeCategoryForSimilarConditions(): void {
    this.setLowerInitialCostScoreCondition("");

    const categoriesCondition = { ...this.categoriesCondition };
    delete categoriesCondition["0"]; // 敷金なし
    delete categoriesCondition["1"]; // 礼金なし
    delete categoriesCondition["5"]; // デザイナーズ
    delete categoriesCondition["9"]; // リノベーション
    delete categoriesCondition["25"]; // 仲介手数料無料
    delete categoriesCondition["28"]; // フリーレント付き
    this.setCategoriesCondition(categoriesCondition);
  }

  get query(): Record<string, string> {
    const query = [
      (query: ParsedUrlQuery) => ConditionReplacer.replaceKeyword(query, this.keywordCondition),
      (query: ParsedUrlQuery) => ConditionReplacer.replaceLowerRent(query, this.lowerRentCondition),
      (query: ParsedUrlQuery) => ConditionReplacer.replaceUpperRent(query, this.upperRentCondition),
      (query: ParsedUrlQuery) => ConditionReplacer.replaceUpperFoot(query, this.upperFootCondition),
      (query: ParsedUrlQuery) =>
        ConditionReplacer.replaceLowerInitialCostScore(query, this.lowerInitialCostScoreCondition),
      (query: ParsedUrlQuery) => ConditionReplacer.replaceLowerArea(query, this.lowerAreaCondition),
      (query: ParsedUrlQuery) => ConditionReplacer.replaceUpperArea(query, this.upperAreaCondition),
      (query: ParsedUrlQuery) => ConditionReplacer.replaceFloorPlan(query, this.floorPlanCondition),
      (query: ParsedUrlQuery) =>
        ConditionReplacer.replaceUpperBuildingAge(query, this.upperBuildingAgeCondition),
      (query: ParsedUrlQuery) =>
        ConditionReplacer.replaceConstruction(query, this.constructionCondition),
      (query: ParsedUrlQuery) => ConditionReplacer.replaceCategory(query, this.categoriesCondition),
      (query: ParsedUrlQuery) =>
        ConditionReplacer.replaceIncludeAdminFree(query, this.includeAdministrationFeeCondition),
    ].reduce((replacedQuery, replacer) => replacer(replacedQuery), {});

    return {
      ...this.routeCondition.query,
      ...this.regionCondition.query,
      ...query,
    };
  }

  get canonicalQuery(): Record<string, string> {
    // .query()に、page条件とorder条件は含まれない
    const canonicalCondition = Conditions.fromQuery(this.query);

    // 沿線駅・市区町村条件をクリア
    canonicalCondition.forceSetRegionCondition(new MunicipalityCondition([], [], []));
    canonicalCondition.forceSetRouteCondition(new RailwayStationCondition([], []));

    // エリア系クエリは、「市区群 > 駅 > 都道府県 > 沿線」の優先順位で、1つだけ残す。
    const regionData = this.regionCondition.optimizedData;
    const routeData = this.routeCondition.optimizedData;

    if (regionData.municipalities.length > 0) {
      canonicalCondition.forceSetRegionCondition(
        new MunicipalityCondition(
          [],
          [],
          regionData.municipalities.map((m) => m.jisX_0401_0402),
        ),
      );
    } else if (routeData.stations.length > 0) {
      canonicalCondition.forceSetRouteCondition(
        new RailwayStationCondition(
          [],
          routeData.stations.map((s) => s.id),
        ),
      );
    } else if (regionData.prefectures.length > 0) {
      canonicalCondition.forceSetRegionCondition(
        new MunicipalityCondition(
          regionData.prefectures.map((m) => m.slug),
          [],
          [],
        ),
      );
    } else if (routeData.railways.length > 0) {
      canonicalCondition.forceSetRouteCondition(
        new RailwayStationCondition(
          routeData.railways.map((r) => r.id),
          [],
        ),
      );
    } else {
      canonicalCondition.forceSetRegionCondition(this.regionCondition);
      canonicalCondition.forceSetRouteCondition(this.routeCondition);
    }

    const query = canonicalCondition.query;

    // articleの条件がある場合は該当の検索用クエリは削除して、a_idに置き換えする
    if (this._article) {
      Object.keys(this._article.search_query).forEach((articleKey) => {
        delete query[articleKey];
      });

      query[QUERY_KEY.ARTICLE] = String(this._article.id);
    }

    return query;
  }

  static fromQuery(query: ParsedUrlQuery): Conditions {
    // 通常のクエリのパース
    const classifiedQuery = this.classifyQuery(query);
    const stringQuery = classifiedQuery.stringQuery;
    const dictionaryQuery = classifiedQuery.dictionaryQuery;

    // articleのクエリのパース
    const selectedArticle = Article.find(String(query.a_id));
    const articleClassfiedQuery = this.classifyQuery(selectedArticle?.search_query || {});
    const articleStringQuery = articleClassfiedQuery.stringQuery;
    const articleDictionaryQuery = articleClassfiedQuery.dictionaryQuery;

    // エリア条件の抽出
    const railwayLegacyCodes = Object.keys(dictionaryQuery[QUERY_KEY.LINE] || {});
    const stationLegacyCodes = Object.keys(dictionaryQuery[QUERY_KEY.STATION] || {});
    const railwayIdsQueryText = stringQuery[QUERY_KEY.RAILWAY_ID];
    const stationIdsQueryText = stringQuery[QUERY_KEY.STATION_ID];
    if (
      (!!railwayIdsQueryText || !!stationIdsQueryText) &&
      (railwayLegacyCodes.length > 0 || stationLegacyCodes.length > 0)
    ) {
      throw IncompatibleError.route();
    }

    const prefectureLegacyCodes = Object.keys(dictionaryQuery[QUERY_KEY.TODOFUKEN] || {});
    const municipalityGroupLegacyCodes = Object.keys(
      dictionaryQuery[QUERY_KEY.SHIKUGUN_GROUP] || {},
    );
    const municipalityLegacyCodes = Object.keys(dictionaryQuery[QUERY_KEY.SHIKUGUN] || {});
    const prefectureSlugsQueryText = stringQuery[QUERY_KEY.PREFECTURE_SLUG];
    const jisCodesQueryText = stringQuery[QUERY_KEY.JIS_X_0401_0402];
    const municipalityGroupIdsQueryText = stringQuery[QUERY_KEY.MUNICIPALITY_GROUP_ID];
    if (
      (!!prefectureSlugsQueryText || !!jisCodesQueryText || !!municipalityGroupIdsQueryText) &&
      (prefectureLegacyCodes.length > 0 ||
        municipalityGroupLegacyCodes.length > 0 ||
        municipalityLegacyCodes.length > 0)
    ) {
      throw IncompatibleError.region();
    }

    // エリア以外の条件の抽出
    const keywordCondition =
      articleStringQuery[QUERY_KEY.KEYWORD] ?? stringQuery[QUERY_KEY.KEYWORD] ?? "";
    const lowerInitialCostScoreCondition =
      articleStringQuery[QUERY_KEY.INITIAL_COST_SCORE] ??
      stringQuery[QUERY_KEY.INITIAL_COST_SCORE] ??
      "";
    const lowerRentCondition =
      articleStringQuery[QUERY_KEY.LOWER_RENT] ?? stringQuery[QUERY_KEY.LOWER_RENT] ?? "";
    const upperRentCondition =
      articleStringQuery[QUERY_KEY.UPPER_RENT] ?? stringQuery[QUERY_KEY.UPPER_RENT] ?? "";
    const includeAdministrationFeeCondition =
      stringQuery[QUERY_KEY.INCLUDE_ADMIN_FREE] === BOOLEAN_TRUE;
    const upperFootCondition =
      articleStringQuery[QUERY_KEY.UPPER_FOOT] ?? stringQuery[QUERY_KEY.UPPER_FOOT] ?? "";
    const lowerAreaCondition =
      articleStringQuery[QUERY_KEY.LOWER_AREA] ?? stringQuery[QUERY_KEY.LOWER_AREA] ?? "";
    const upperAreaCondition =
      articleStringQuery[QUERY_KEY.UPPER_AREA] ?? stringQuery[QUERY_KEY.UPPER_AREA] ?? "";
    const lowerBuildingAgeCondition =
      articleStringQuery[QUERY_KEY.LOWER_BUILDING_AGE] ??
      stringQuery[QUERY_KEY.LOWER_BUILDING_AGE] ??
      "";
    const upperBuildingAgeCondition =
      articleStringQuery[QUERY_KEY.UPPER_BUILDING_AGE] ??
      stringQuery[QUERY_KEY.UPPER_BUILDING_AGE] ??
      "";
    const floorPlanCondition = {
      ...dictionaryQuery[QUERY_KEY.FLOOR_PLAN],
      ...articleDictionaryQuery[QUERY_KEY.FLOOR_PLAN],
    };
    const constructionCondition = {
      ...dictionaryQuery[QUERY_KEY.CONSTRUCTION],
      ...articleDictionaryQuery[QUERY_KEY.CONSTRUCTION],
    };
    const categoriesCondition = {
      ...dictionaryQuery[QUERY_KEY.CATEGORY],
      ...articleDictionaryQuery[QUERY_KEY.CATEGORY],
    };
    const orderCondition =
      articleStringQuery[QUERY_KEY.ORDER] || stringQuery[QUERY_KEY.ORDER] || "recommend";
    const hasOrderConditionQuery = Boolean(
      articleStringQuery[QUERY_KEY.ORDER] || stringQuery[QUERY_KEY.ORDER],
    );
    const pageCondition = stringQuery[QUERY_KEY.PAGE] || "1";
    const propertyAgentNameCondition = stringQuery[QUERY_KEY.PROPERTY_AGENT_NAME] || "";

    return new this(
      {
        keywordCondition,
        lowerInitialCostScoreCondition,
        lowerRentCondition,
        upperRentCondition,
        includeAdministrationFeeCondition,
        upperFootCondition,
        lowerAreaCondition,
        upperAreaCondition,
        floorPlanCondition,
        lowerBuildingAgeCondition,
        upperBuildingAgeCondition,
        constructionCondition,
        categoriesCondition,
        orderCondition,
        hasOrderConditionQuery,
        pageCondition,
        propertyAgentNameCondition,
        railwayLegacyCodes,
        stationLegacyCodes,
        railwayIdsQueryText,
        stationIdsQueryText,
        prefectureLegacyCodes,
        municipalityGroupLegacyCodes,
        municipalityLegacyCodes,
        prefectureSlugsQueryText,
        municipalityGroupIdsQueryText,
        jisCodesQueryText,
      },
      {
        stringQuery,
        dictionaryQuery,
        articleStringQuery,
        articleDictionaryQuery,
      },
      query,
      selectedArticle,
    );
  }

  /** URLクエリを文字列型と辞書型に分類する */
  private static classifyQuery(query: ParsedUrlQuery) {
    const stringQuery: StringQuery = {};
    const dictionaryQuery: DictionaryQuery = {};

    for (const [key, value] of Object.entries(query)) {
      // dから始まる場合は辞書型の引数とみなす
      // ただし、フリーテキストが入ってくるk(キーワード)とo(並び順)は、偶然の一致を防ぐため除外する
      if (!!value) {
        if (value.slice(0, 1) === "d" && key !== QUERY_KEY.KEYWORD && key !== QUERY_KEY.ORDER) {
          const tmp: Record<string, boolean> = {};
          String(value)
            .slice(2)
            .split("-")
            .forEach((i) => {
              if (i) tmp[i] = true;
            });
          dictionaryQuery[key] = tmp;
        } else {
          stringQuery[key] = String(value);
        }
      }
    }

    return { stringQuery, dictionaryQuery };
  }
  get prefecture(): Prefecture | undefined {
    return this.routeCondition.prefecture || this.regionCondition.prefecture;
  }
}
