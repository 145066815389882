import { ConditionInterface } from "../ConditionInterface";
import { Municipality } from "~/types/Models/Municipality";
import {
  getFulfilledGroups,
  MunicipalityGroup,
  NotQueryableMunicipalityGroup,
  QueryableMunicipalityGroup,
} from "~/types/Models/MunicipalityGroup";
import { getFulfilledPrefectures, Prefecture } from "~/types/Models/Prefecture";
import { sortBy, uniqueBy } from "~/utils/array";

type RegionConditionType = {
  prefectures: Prefecture[];
  queryableGroups: QueryableMunicipalityGroup[];
  notQueryableGroups: NotQueryableMunicipalityGroup[];
  municipalities: Municipality[];
};
export interface RegionConditionInterface extends ConditionInterface {
  query: Record<string, string>;
  optimizedData: RegionConditionType;
  prefecture: Prefecture | undefined;
}
export abstract class AbstractRegionCondition implements RegionConditionInterface {
  // 初期化された時の設定を管理する
  abstract legacy: boolean;
  protected abstract setSourceData(): void;
  protected _sourceData: {
    prefectures: Prefecture[];
    municipalityGroups: MunicipalityGroup[];
    municipalities: Municipality[];
  };

  // 最小の条件数になるように集約したデータ
  //・都道府県
  //   ・任意の都道府県に含まれる市区町村グループが全て設定されている場合は、都道府県の条件のみを有効とする
  // ・市区町村グループ
  //　　・すでに都道府県に条件がある場合は省略
  //　　・市区町村グループに含まれる市区町村コードがすべて設定されている場合は、市区町村グループコードの条件のみを有効とする
  // ・市区町村グループ
  //　　・すでに都道府県もしくは、市区町村グループに設定がある場合は省略
  private _optimizedData: RegionConditionType;

  get optimizedData() {
    if (!this._optimizedData) this.setOptimizedData();
    return this._optimizedData;
  }

  private setOptimizedData(): void {
    if (!this._sourceData) this.setSourceData();

    const fulfilledGroups = getFulfilledGroups(
      this._sourceData.municipalities.map((m) => m.jisX_0401_0402),
    );
    const groups = [...this._sourceData.municipalityGroups, ...fulfilledGroups];

    const fulfilledPrefs = getFulfilledPrefectures(groups.map((g) => g.slug));
    const optimizedPrefs = uniqueBy([...this._sourceData.prefectures, ...fulfilledPrefs], "slug");

    // 都道府県条件にある市区町村グループは省略
    const optimizedGroups = uniqueBy(
      groups.filter((g) => !optimizedPrefs.some((p) => p.slug === g.prefectureSlug)),
      "slug",
    );
    const optimizedQueryable = optimizedGroups.filter(
      (g): g is QueryableMunicipalityGroup => g.id !== undefined,
    );
    const optimizedNotQueryable = optimizedGroups.filter(
      (g): g is NotQueryableMunicipalityGroup => g.id === undefined,
    );
    // 都道府県条件もしくは、市区町村グループにある市区町村は省略
    const optimizedMuni = uniqueBy(
      this._sourceData.municipalities
        .filter((m) => !optimizedPrefs.some((p) => p.slug === m.prefectureSlug))
        .filter((m) => !optimizedGroups.some((g) => g.slug === m.municipalityGroupSlug)),
      "jisX_0401_0402",
    );

    this._optimizedData = {
      prefectures: sortBy(optimizedPrefs, ["jisX_0401"]),
      queryableGroups: sortBy(optimizedQueryable, ["id"]),
      notQueryableGroups: sortBy(optimizedNotQueryable, ["jisX_0401"]),
      municipalities: sortBy(optimizedMuni, ["jisX_0401_0402"]),
    };
  }

  get hasAnyCondition(): boolean {
    return (
      this.optimizedData.prefectures.length > 0 ||
      this.optimizedData.queryableGroups.length > 0 ||
      this.optimizedData.notQueryableGroups.length > 0 ||
      this.optimizedData.municipalities.length > 0
    );
  }

  get variantCount(): number {
    let count = 0;
    this.optimizedData.prefectures.length > 0 && ++count;
    this.optimizedData.queryableGroups.length > 0 && ++count;
    (this.optimizedData.notQueryableGroups.length > 0 ||
      this.optimizedData.municipalities.length > 0) &&
      ++count;

    return count;
  }

  get conditionCount(): number {
    return (
      this.optimizedData.prefectures.length +
      this.optimizedData.queryableGroups.length +
      this.optimizedData.notQueryableGroups
        .map((g) => g.jisX_0401_0402Codes.length)
        .reduce((sum, len) => sum + len, 0) +
      this.optimizedData.municipalities.length
    );
  }

  get query(): Record<string, string> {
    const notQueryGroupMunicipalities =
      this.optimizedData.notQueryableGroups.length === 0
        ? []
        : this.optimizedData.notQueryableGroups.flatMap((g) => g.municipalities);
    const municipalities = [...notQueryGroupMunicipalities, ...this.optimizedData.municipalities];

    return {
      ...(this.optimizedData.prefectures.length > 0
        ? this.joinQueries(
            this.optimizedData.prefectures[0].queryKey,
            this.optimizedData.prefectures.map((p) => p.query[p.queryKey]),
          )
        : {}),
      ...(this.optimizedData.queryableGroups.length > 0
        ? this.joinQueries(
            this.optimizedData.queryableGroups[0].queryKey,
            this.optimizedData.queryableGroups.map((p) => p.query[p.queryKey]),
          )
        : {}),
      ...(municipalities.length > 0
        ? this.joinQueries(
            municipalities[0].queryKey,
            municipalities.map((p) => p.query[p.queryKey]),
          )
        : {}),
    };
  }

  get prefecture(): Prefecture | undefined {
    if (!this.hasAnyCondition) return;
    if (this.optimizedData.prefectures[0]) return this.optimizedData.prefectures[0];
    if (this.optimizedData.queryableGroups[0])
      return this.optimizedData.queryableGroups[0].prefecture;
    if (this.optimizedData.notQueryableGroups[0])
      return this.optimizedData.notQueryableGroups[0].prefecture;
    return this.optimizedData.municipalities[0].prefecture;
  }

  private joinQueries(queryKey: string, values: string[]): Record<string, string> {
    return {
      [queryKey]: values.join(","),
    };
  }
}
